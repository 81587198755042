import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import configureStore from './redux/configureStore';
import './index.css';
import NavBar from './NavBar';

const store = configureStore();

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <NavBar />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

