import React, {Component} from 'react';
import {  PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import {LIGHT_COLOUR, MIDDLE_COLOUR, HEAVY_COLOUR, EMPTY_COLOUR } from '../../constants';

import './PublishedTotal.css';

class PublishedTotal extends Component {   
    constructor(props) {
        super(props);

        this.state = {
            tankLevel: null,
            tankVolumne: null,
            data: null,
            colors: null, 
            commodityType: this.props.commodityType
        }

        this.configureChart = this.configureChart.bind(this)
        this.configureChartColours = this.configureChartColours.bind(this)

    }

    componentWillMount() {
        this.configureChart();
        this.configureChartColours();
    }

    configureChart() {

        if (this.props.level == null || this.props.volume == null) {
            return;
        }

        // Calculate volume percentage
        let level = (this.props.level / this.props.volume) * 100; 
        let volume = 100 - level;

        this.setState({
            tankLevel: level,
            tankVolume: volume,
            data: [{name: 'Light', value: level},{name: 'Total Volume', value: volume}]
        });
    }

    configureChartColours() {

        switch (this.props.commodityType) {
            case 'light': 
                this.setState({
                    colors: [LIGHT_COLOUR, EMPTY_COLOUR]
                })
                break; 
            case 'middle': 
                this.setState({
                    colors: [MIDDLE_COLOUR, EMPTY_COLOUR]
                })
                break; 
            case 'heavy':
                this.setState({
                    colors: [HEAVY_COLOUR, EMPTY_COLOUR]
                })
                break; 
            default: 
                this.setState({
                    colors: [EMPTY_COLOUR, EMPTY_COLOUR]
                })
                break;
        }

    } 
    
    render() {

        if (this.state.data == null) {
            return (null);
        }

        var changeColour = "noChange";
        var changeIcon = "arrow-right";

        if (this.props.change < 0) {
            changeColour = "lower";
            changeIcon = "glyphicon glyphicon-arrow-down";
        } else if (this.props.change > 0) {
            changeColour = "higher";
            changeIcon = "glyphicon glyphicon-arrow-up";
        }
       
        return (        
            <div className="publishedTotalContainer">
                <div className="display-information">
                    <p className={changeColour}><i className={changeIcon}></i>{this.props.change}%</p> 
                    <h3>{this.props.level}</h3>
                    <span className="commodityTitle">{this.props.title}</span>
                </div>
                <ResponsiveContainer height={260}>
                    <PieChart onMouseEnter={this.onPieEnter}>
                        <Pie
                            data={this.state.data} 
                            innerRadius={85}
                            outerRadius={110} 
                            fill="#ffffff"
                            paddingAngle={0}
                            margin={0}
                            startAngle={0}
                            stroke={0}
                            dataKey="value"
                            >
                                {
                                    this.state.data.map((entry, index) => <Cell fill={this.state.colors[index % this.state.colors.length]} key={index}/>)
                                }
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                {/*cornerRadius={index === 0 ? 30 : 0}*/}
            </div>
        ); 
    }
}

export default PublishedTotal;