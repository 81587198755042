import React, { Component } from 'react';
import {Form, FormGroup, FormControl, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchUser } from '../../redux/auth/actions/auth';

// Css 

import './LoginView.css';

// Modules 
import Loading from '../../Modules/Loading';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object
};

class LoginView extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            email: '',
            password: '',
            isLoggedIn: false, 
            loading: false
        }

        this.handleSignIn = this.handleSignIn.bind(this);
        this.setLoading = this.setLoading.bind(this);

    }
    
    setLoading(isLoading) {
        this.setState({
            loading: isLoading
        })
    }

    handleSignIn(e) {               
        e.preventDefault();
        const { email, password } = this.state;        
        this.props.dispatch(fetchUser({ email, password }));        
    }

    handleChange(column, value) {
        const newState = {};
        newState[column] = value;

        this.setState(newState);
    }

    resetPassword() {
        window.location.href = '/reset';
    }

    render() {

        const { auth } = this.props;        

        return(
            <div id="login-view">
                <div className="loginLogo"></div>
                <div className="loginContainer">                    
                    <div className="titleContainer">
                        <h2>Secure Login</h2>
                        <p>Enter your login details to gain access to your secure submission portal.</p>
                        {auth.error && <p className="error">{auth.error}</p>}
                    </div>
                    <div className="formContainer">                        
                        <Form onSubmit={e => this.handleSignIn(e)}>
                            <div className="padding">
                                <FormGroup controlId="formBasicText" className="form-group">
                                    <FormControl type="text" placeholder="Email address" onChange={e => this.handleChange('email', e.target.value.replace(/ /g,''))} className="username"/>                            
                                </FormGroup>
                                <FormGroup controlId="formPassword" className="form-group">
                                    <FormControl type="password" placeholder="Password"  autocomplete="off" onChange={e => this.handleChange('password', e.target.value.replace(/ /g,''))} className="password"/>
                                </FormGroup>                                
                            </div>
                            <FormGroup controlId="formPassword" className="form-group">
                                <Button type="submit" className="login-button">Login</Button>
                            </FormGroup>
                        </Form>
                    </div>
                    <Button type="button"  className="forgotLink btn-link" onClick={this.resetPassword.bind(this)}>Forgot your password?</Button>
                </div>   
                <Loading visible={this.state.loading} />                   
            </div>
        );
    }
}

function select({ auth }) {
  return { auth };
}

LoginView.propTypes = propTypes;

export default connect(select)(LoginView); 
