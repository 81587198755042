import React, { Component } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, Legend, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

import DropDownMenu from '../DropDownMenu';
import {LIGHT_COLOUR, MIDDLE_COLOUR, HEAVY_COLOUR, getAPIPath, USER_TYPE, headersWithMethod, fetchAPI} from '../../constants';

import './LevelsBarChart.css';

class LevelsBarChart extends Component {

    constructor(props) {
        super(props);
        this.exportData = this.exportData.bind(this);
    }

    exportData() {        
        const APIPATH = getAPIPath(USER_TYPE.DOWNLOAD);
        fetchAPI(APIPATH + '/export', headersWithMethod('POST',
            JSON.stringify({                    
                "fields": ['name','light','middle','heavy'],
                "data": this.props.data, 
                "fileName": "Submission-History"
        }))).then((response) => {                     
            response.json().then((json) => {
                window.open(APIPATH + '/exports/' + json.file);
            });                               
            
        }).catch((error) =>{
            console.error('ERROR:', error);
        })
    }

    render() {     
        
        class SimpleBarChart extends Component {        
            render () {
                return (
                        <ResponsiveContainer height={500}>
                            <ComposedChart data={this.props.data}>
                                <XAxis dataKey="name" tick={{fill: 'white'}}/>
                                <YAxis tick={{fill: 'white'}}/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip/>
                                <Legend />            
                                <Bar dataKey="light" fill={LIGHT_COLOUR} barSize={60} fillOpacity={0.9}/>
                                <Bar dataKey="middle" fill={MIDDLE_COLOUR} barSize={60} fillOpacity={0.9}/>
                                <Bar dataKey="heavy" fill={HEAVY_COLOUR} barSize={60} fillOpacity={0.9}/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    );
             }
        }

        return (    
            <div id="levels-bar-chart" className="moduleContainer">   
                <h2>{this.props.title} <DropDownMenu exportEvent={this.exportData} /></h2>                         
                <SimpleBarChart data={this.props.data}/>                
            </div>
        );
    }
}

export default LevelsBarChart;