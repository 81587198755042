import { getAPIPath, USER_TYPE, headersWithMethod, fetchAPI } from '../constants';

const APIPATH = getAPIPath(USER_TYPE.GLOBAL);

export const globalService = {
    getCurrentPeriod: function() {
        return new Promise((resolve, reject) => {    
            fetch(APIPATH + '/getLatestAggregates', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {                                        
                resolve(json);  
            }).catch((error) => {
                console.error('Error getting current period: ' + error);
            });
        });
    }, 
    /* Public 
     Get published totals from API */
    getPublishedTotals: function() {
        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/getLatestAggregates', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {                                
                resolve(json.commodities);  
            }).catch((error) => {
                console.error('Error getting published totals: ' + error);
            });
        });
    },     
    publishAggregateTotals: function(currentPeriod, ipAddress, lightValue, middleValue, heavyValue, lightCapacityValue, middleCapacityValue, heavyCapacityValue) {        
        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/publishAggregateAmount', headersWithMethod('POST',JSON.stringify({
                    "currentPeriod": currentPeriod, "ipAddress": ipAddress,                
                    "submissions":[
                        {"commodityType": "light", "amount": lightValue, "capacity": lightCapacityValue},
                        {"commodityType": "middle", "amount": middleValue, "capacity": middleCapacityValue},
                        {"commodityType": "heavy", "amount": heavyValue, "capacity": heavyCapacityValue}
                    ]
                }))
            ).then((response) => {
                resolve(response);
            }).catch((error) =>{
                console.error('ERROR:', error);
            })
        });
    }
}