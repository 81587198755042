import React, { Component } from 'react';
import {Form, Button} from 'react-bootstrap';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchUser } from '../../redux/auth/actions/auth';

// Css 
import './ActivateView.css';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object
};

class ActivateView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: this.props.match.params.email,
            password: '', 
            newPwd: '', 
            confirmNewPwd: '',
            passwordValidation: true, 
            passwordValidationMessage: null
        }
        
        this.handleSignIn = this.handleSignIn.bind(this);
    }
    
    handleSignIn(e) {
        e.preventDefault();

        if (this.validateNewPassword()) {
            const { email, password, newPwd } = this.state;     
            this.props.dispatch(fetchUser({ email, password, newPwd }));
        }
        
    }

    handleChange(column, value) {
        const newState = {};
        newState[column] = value;

        this.setState(newState);
    }

    validateNewPassword() {

        if (this.state.newPwd !== this.state.confirmNewPwd) {
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password and confirm password both do not match."
            });

            return false; 
        }

        if (this.state.newPwd.length < 9) {
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password must be at least 9 characters or more in length."
            });
            return false; 
        }

        // Check for numbers 
        var r = /\d+/;        
        if (this.state.newPwd.match(r) == null) {
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password must contain at least one number."
            });
            return false; 
        }

        // Check for lowercase 
        r = /[a-z]/;        
        if (this.state.newPwd.match(r) == null) {
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password must contain at least one lowercase letter."
            });
            return false; 
        }

        // Check for uppercase
        r = /[A-Z]/;        
        if (this.state.newPwd.match(r) == null) {
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password must contain at least one uppercase letter."
            });
            return false; 
        }

        // Check for special characters
        r = /\W/;  
        if(this.state.newPwd.match(r) == null){
           this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password must contain at least one special character."
            });
            return false; 
        }     

        return true; 
    }

    render() {

        const { auth } = this.props;        

        return(
            <div id="activate-view">
                <div className="loginLogo"></div>
                <div className="activateContainer">                    
                    <div className="titleContainer">
                        <h2>Activate your account</h2>
                        <p>Enter the password provided and a new password to activate your account. All passwords must: </p>
                             <ul>
                                <li>be a minimum of 9 characters</li>
                                <li>Include at least one number, special character, uppercase and lowercase letters.</li>
                            </ul>
                        {auth.error && <p className="error">{auth.error}</p>}
                        {!this.state.passwordValidation && <p className="error">{this.state.passwordValidationMessage}</p>}
                    </div>
                    <div><br /></div>
                    <div className="formContainer">                        
                        <Form onSubmit={e => this.handleSignIn(e)}>
                            <div className="padding">
                                <Form.Group controlId="formBasicText">
                                    <Form.Control type="text" placeholder="Email address" onChange={e => this.handleChange('email', e.target.value)} className="username" readOnly value={this.state.email}/>
                                    <Form.Control.Feedback />
                                </Form.Group>
                                <Form.Group controlId="formPassword">
                                    <Form.Control type="password" placeholder="Temporary Password" autocomplete="off" onChange={e => this.handleChange('password', e.target.value)} className="password"/>
                                    <Form.Control.Feedback />
                                </Form.Group>
                                <Form.Group controlId="formPassword">
                                    <Form.Control type="password" placeholder="New Password" autocomplete="off" onChange={e => this.handleChange('newPwd', e.target.value)} className="password"/>
                                    <Form.Control.Feedback />
                                </Form.Group>
                                <Form.Group controlId="formPassword">
                                    <Form.Control type="password" placeholder="Confirm New Password" autocomplete="off" onChange={e => this.handleChange('confirmNewPwd', e.target.value)} className="password"/>
                                    <Form.Control.Feedback />
                                </Form.Group>
                            </div>
                            <Form.Group controlId="formPassword">
                                <Button type="submit" bsStyle="primary" className="activate-button">Submit</Button>
                            </Form.Group>
                        </Form>
                    </div>                    
                </div>                      
            </div>
        );
    }
}

function select({ auth }) {
  return { auth };
}

ActivateView.propTypes = propTypes;

export default connect(select)(ActivateView); 
