import React, { Component } from 'react';

// Helpers 
import { getStatusClass, getStatusText } from '../../helpers';

class SubmissionStatusIcon extends Component {

    render() {

        if (this.props.status == null) {
            return(null);
        }

        const status = this.props.status
        const regulatorComment = this.props.regulatorComment
        const isAggregates = this.props.isAggregates
        const publisherComment = this.props.publisherComment

        if(isAggregates){
           return(<div><span className={getStatusClass(status)} data-tip={getStatusText(status, publisherComment)}></span></div>);
        }
        return(<div><span className={getStatusClass(status)} data-tip={getStatusText(status, regulatorComment)}></span></div>);
    }

}

export default SubmissionStatusIcon;