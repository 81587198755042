import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Views 
import SummaryDataView from '../SummaryDataView';
import PlattsPublishedTotalsView from '../PlattsPublishedTotalsView';
import TotalsPieChartView from '../TotalsPieChartView';

// Modules 
import LevelsBarChart from '../../Modules/LevelsBarChart';
import SubmissionsDataTable from '../../Modules/SubmissionsDataTable';
import OperatorSubmissionSummary from '../../Modules/OperatorSubmissionSummary';
import PlattsSubmissionsDataTable from '../../Modules/PlattsSubmissionsDataTable';

// Services 
import { regulatorService } from '../../Services/regulatorService.js';

class RegulatorView extends Component {

    constructor(props) {
        super(props);

         this.state = {
            data: null, 
            commodityType: 0, 
            aggregateTotalsData: null,
            historicAggregateTotalsData: null,
            submissionSummaryData: null, 
            historicAggregateSubmissionsData: null, 
            loading: true
        };

        this.setLoading = this.setLoading.bind(this);
        this.getSubmissions = this.getSubmissions.bind(this);
        this.getHistoricAggregateTotals = this.getHistoricAggregateTotals.bind(this);
        this.showAreaChartFor = this.showAreaChartFor.bind(this);
        this.getHistoricAggregateSubmissions = this.getHistoricAggregateSubmissions.bind(this);
        this.getCurrentAggregateTotals = this.getCurrentAggregateTotals.bind(this);
        this.getCurrentAggregateTotals = this.getCurrentAggregateTotals.bind(this);
        this.getSubmissionSummary = this.getSubmissionSummary.bind(this);

        this.getSubmissions();
        this.getHistoricAggregateSubmissions();
    }

    componentDidMount() {
        // this.getSubmissions();
        // this.getHistoricAggregateTotals();
        // this.getSubmissionSummary();
        // this.getCurrentAggregateTotals();        
    }

    setLoading(isLoading) {
        this.props.loadingHandler(isLoading);
    }

    getHistoricAggregateSubmissions() {
        this.setLoading(true);
        regulatorService.getHistoricalAggregateSubmissions().then(data => {            
            this.setState({
                historicAggregateSubmissionsData: data
            });
            this.setLoading(false);
        }).catch(error => {
            console.log("ERROR", error);
        })
    }

    getHistoricAggregateTotals() {
        this.setLoading(true);
        regulatorService.getHistoricAggregateTotals().then((response) => {            
            this.setState({
                historicAggregateTotalsData: response
            });            
            this.setLoading(false);
        });
    }

    showAreaChartFor(commodityType) {        
        this.setState({
            commodityType: commodityType
        });
    }

    getSubmissions() {
        this.setLoading(true);
        regulatorService.getSumbissionTableData().then((response) => {     
            this.setLoading(false);

            this.setState({
                data: response
            });

            this.getHistoricAggregateTotals();
            this.getSubmissionSummary();
            this.getCurrentAggregateTotals();              

        });
    }

    getSubmissionSummary() {
        this.setLoading(true);
        regulatorService.getOperatorSubmissionSummary().then((response) => {

            if (response === undefined || response === null) {
                return;
            }

            this.setState({
                submissionSummaryData: response
            }) 

            this.setLoading(false);
            this.props.submissionDateHandler(response.currentPeriod);
        });
    }

    getCurrentAggregateTotals() {
        this.setLoading(true);
        regulatorService.getCurrentAggregateForPeriod().then((response) => {            
            this.setState({                
                aggregateTotalsData: response
            });
            this.setLoading(false);
        })
    }

    render () {
        return (
            <div id="regulator-view">                         
                <Container>
                    <Row>
                        <Col>                        
                            <TotalsPieChartView data={this.state.data}/>
                        </Col>
                    </Row>
                     <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <OperatorSubmissionSummary data={this.state.submissionSummaryData} />                            
                        </Col>
                    </Row>
                     <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubmissionsDataTable data={this.state.data} title="Latest submissions" loadingHandler={this.setLoading} summaryData={this.state.submissionSummaryData}/>
                        </Col>
                    </Row>
                     <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <SummaryDataView horizontal data={this.state.aggregateTotalsData}/>
                        </Col>
                        <Col sm={8}>
                            <LevelsBarChart data={this.state.historicAggregateTotalsData} commodityType={this.state.commodityType} title="Submissions history"/>
                        </Col>
                    </Row>
                     <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>                                                        
                            <PlattsPublishedTotalsView horizontal/>
                        </Col>
                    </Row>
                     <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PlattsSubmissionsDataTable data={this.state.historicAggregateSubmissionsData} title="Previous aggregate submissions" showHistory={true}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                </Container>                
            </div>
        )
    }
}

export default RegulatorView; 