
import { STATUS } from '../constants';

export function getStatusClass(status) {
    var statusClasses = "status-indicator";
    switch (status) {
      case STATUS.SUBMITTED: 
        statusClasses += " neutral";
        break;
      case STATUS.ACCEPTED: 
      case STATUS.PUBLISH: 
        statusClasses += " approved";
        break; 
      case STATUS.REJECTED: 
        statusClasses += " declined";
        break;
      default: 
        statusClasses = "";
    }   
    return statusClasses;
  };

  export function getStatusText(status, comment) {
    
    switch (status) {
      case STATUS.SUBMITTED: 
        return "Submitted";        
      case STATUS.ACCEPTED: 
      case STATUS.PUBLISH: 
        return "Approved";
      case STATUS.REJECTED: 
        return "Declined" + ": " + comment;
      default: 
        return "-";
    }       
  };

function pad(value) {
    return value < 10 ? '0' + value : value;
}
export function createOffset(date) {
    var sign = (date.getTimezoneOffset() > 0) ? "+" : "-";
    var offset = Math.abs(date.getTimezoneOffset());
    var hours = pad(Math.floor(offset / 60));
    var minutes = pad(offset % 60);
    return sign + hours + ":" + minutes;
}

