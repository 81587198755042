import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

class DeclineReasonModal extends Component {
    
    constructor(props) {
        super(props); 
        
        this.state = {
            showModal: true, 
            reason: null
        };

        this.closeModal = this.closeModal.bind(this)
        this.submitReason = this.submitReason.bind(this)
    }

    closeModal() {
        this.props.closeAction();
    }

    submitReason() {
        this.props.declineAction(this.props.selectedItemIndex, this.state.reason);        
    }
    
    handleReasonChange(e) {
        this.setState({
            reason: e.target.value
        });
    }

    render() {

        const modalInstance = (
            <div className="static-modal">
                <Modal animation={false} show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header>
                        <Modal.Title>Reason</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                        
                        <Form.Group>
                            <Form.Label>Enter a reason for declining this submission:</Form.Label>
                            <textarea placeholder="textarea" onChange={this.handleReasonChange.bind(this)} class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal} variant="default" className="okButton">Cancel</Button>
                        <Button onClick={this.submitReason} variant="success" className="okButton" disabled={this.state.reason == null}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );

        return(modalInstance)
    }
}

export default DeclineReasonModal; 