import React from 'react';
import DropdownMenu from 'react-dd-menu';

import './DropDownMenu.css';

export default class DropDownMenu extends React.Component {
  constructor() {
    super();
    this.state = {
        isMenuOpen: false
    };
    this.exportData = this.exportData.bind(this);
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
  }

  toggle() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  close() {
    this.setState({ isMenuOpen: false });
  }

  exportData() {
      this.props.exportEvent();
  }


  render() {
    const menuOptions = {
      isOpen: this.state.isMenuOpen,
      close: this.close,
      toggle: <button type="link" onClick={this.toggle}>...</button>,
      align: 'right',       
      className: 'test', 
      animAlign: 'right', 
      menuAlign: 'right'
    };
    return (
      null
      // <DropdownMenu {...menuOptions}>
      //   <li><button type="link" onClick={this.exportData}>Export to CSV</button></li>        
      // </DropdownMenu>
    );
  }
}