import { take, call, put } from 'redux-saga/effects';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { LoadEnvironmentValues,environmentList } from '../../../constants';

import {
  fetchLoginState,
  failFetchingLoginState,
  fetchUser,
  failFetchingUser,
  login,
  clickLogout,
  logout
} from '../actions/auth';

// BETA - ENV
// const poolData = {
//   UserPoolId: 'eu-west-1_jeQu2N8o8',
//   ClientId: '8db0hesj18pms95civrtkbjsd'
// };

let currentEnv = window.location.host.split('.')[0];
let listEnv = Object.values(environmentList);
let filteredEnv = listEnv.includes(currentEnv) ? currentEnv : 'prod';
const configParams = LoadEnvironmentValues(filteredEnv);

const poolData = {
  UserPoolId: configParams.USER_POOL_ID,
  ClientId: configParams.CLIENT_ID
};

const userPool = new AWSCognito.CognitoUserPool(poolData);

const getSession = cognitoUser =>
  new Promise((resolve, reject) => {
    cognitoUser.getSession((err, result) => {
      if (result) {
        cognitoUser.getUserAttributes((err, attrs) => {
          if (err) {
            resolve({ payload: null, err });
          } else {
            const payload = {};
            payload.user = {};
            attrs.forEach(attr => (payload.user[attr.Name] = attr.Value));
            payload.jwt = result.getIdToken().getJwtToken();
            payload.jwtAccessToken = result.getAccessToken().getJwtToken();
            resolve({ payload });
          }
        });
      } else {
        resolve({ payload: null, err });
      }
    });
  });

const cognitoSignIn = params =>
  new Promise((resolve, reject) => {
    const { email, password, newPwd } = params;
    const authenticationDetails = new AWSCognito.AuthenticationDetails({
      Username: email,
      Password: password
    });

    const cognitoUser = new AWSCognito.CognitoUser({
      Username: email,
      Pool: userPool
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        cognitoUser.getUserAttributes((err, attrs) => {
          const payload = {};
          attrs.forEach(attr => (payload[attr.Name] = attr.Value));
          payload.jwt = result.getIdToken().getJwtToken();
          payload.jwtAccessToken = result.getAccessToken().getJwtToken();
          resolve({ payload });
        });
      },
      onFailure: err => {
        resolve({ payload: null, err });
      },
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        //console.log("user attr",userAttributes)
        // User was signed up by an admin and must provide new
        // pwd and required attributes, if any, to complete
        // authentication.            
        // the api doesn't accept this field back
        if (userAttributes.email) {
          delete userAttributes.email;
        }
        if (userAttributes.phone_number) {
          delete userAttributes.phone_number;
        }
        delete userAttributes.email_verified;
        delete userAttributes.phone_number_verified;
        delete userAttributes["custom:organisationId"];
        delete userAttributes["custom:organisationType"];
        delete userAttributes["custom:admin"];
        // Get these details and call
        cognitoUser.completeNewPasswordChallenge(newPwd, userAttributes, this);
      },
      mfaRequired: function (codeDeliveryDetails) {
        var verificationCode = prompt('Please input verification code', '');
        cognitoUser.sendMFACode(verificationCode, this);
      }
    });
  });

const getAccessToken = cognitoUser =>
  new Promise((resolve, reject) => {
    cognitoUser.getSession((err, result) => {
      if (result) {
        const token = result.getAccessToken().getJwtToken();
        resolve({ token });
      } else {
        resolve({ token: null, err });
      }
    });
  });

const globalSignOut = cognitoUser =>
  new Promise((resolve, reject) => {
    cognitoUser.globalSignOut({
      onSuccess: result => {
        resolve({ result });
      },
      onFailure: err => {
        resolve({ result: null, err });
      }
    });
  });

const setCookie = (name, value, days = 1, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires};path=${path}`;

}

const deleteCookie = (name) => {
  setCookie(name, '')
}

export function* handleFetchLoginState() {
  while (true) {
    const action = yield take(`${fetchLoginState}`);

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      const { payload, err } = yield call(getSession, cognitoUser);

      if (payload) {
        window.resetTimer();
        window.addEventListener('logout', e => {
          singOut();
        }, false);
      }
      else {
        window.removeEventListener('logout', e => {
          singOut();
        }, false);
      }
      if (payload && !err) {
        yield put(login(Object.assign({}, payload, action.payload)));
        continue;
      }
      yield put(failFetchingLoginState(action.payload));
      continue;
    }
    yield put(failFetchingLoginState(''));
  }
}

export function* handleLogout() {
  while (true) {
    yield take(`${clickLogout}`);

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      const { token, err } = yield call(getAccessToken, cognitoUser);

      if (token && !err) {
        const { result, err } = yield call(globalSignOut, cognitoUser);
        if (result && !err) {
          deleteCookie("jwt");
          yield put(logout());
        }
      }
    }
  }
}

export function* handleLogin() {
  while (true) {
    const action = yield take(`${fetchUser}`);
    const { email, password } = action.payload;

    if (email && password) {
      const { payload, err } = yield call(cognitoSignIn, action.payload);

      if (!payload && err) {
        yield put(failFetchingUser(`${err.statusCode}: ${err.message}`));
        continue;
      }
      // Passing ID token to APIs
      setCookie("jwt", payload.jwt);

      yield put(login(payload));
      continue;
    }
    yield put(failFetchingUser('Please set email and password'));
  }
}

function singOut(){
  const cognitoUser = userPool.getCurrentUser();
  globalSignOut(cognitoUser).then(result => {
  console.log("signedout successfully");
  deleteCookie("jwt");
  window.location.href = '/';
  }).catch(error =>{
    console.log("signout error",error)
  });

}



