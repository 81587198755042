import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PublishedTotal from '../../Modules/PublishedTotal';

// Services 
import {globalService} from '../../Services/globalService.js';

class PlattsPublishedTotalsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        }

        this.getData();
    }

    getData() {
        globalService.getPublishedTotals().then((response) => {
            if (response && response.length > 0) {
                this.setState({
                    data: response
                })
             }
        });
    }
    
    render() {
        const title = (<h2>Current FEDCom/Platts Fujairah Inventory Data</h2>);

        if (this.state.data == null) {
            return null; 
        }

        var columns = []; 

        if (this.props.horizontal) {            
            this.state.data.map((item, i) => 
                columns.push(<Col sm={4} key={i}><PublishedTotal title={item.commodity.name} level={item.amount} volume={item.capacity} change={item.change} commodityType={item.commodity.type} /></Col>)
            );
        } else {
            this.state.data.map((item, i) => 
                columns.push(<Row key={i}><Col key={i}><PublishedTotal title={item.commodity.name} level={item.amount} volume={item.capacity} change={item.change} commodityType={item.commodity.type} /></Col></Row>)
            );
        }

        const horizontalView = (  
            <Container>
                <Row>
                    <Col>
                        {title}
                    </Col>
                </Row>
                <Row>
                    {columns}
                </Row>
                <Row>
                    <Col>
                        <span className="small">* This data provides the aggregate breakdown of heavy distillates &amp; residues, middle distillates and light distillates.</span>
                    </Col>
                </Row>
            </Container>
        );

        const verticalView = (
            <Container>
                <Row>
                    <Col>
                        {title}
                    </Col>
                </Row>
                {columns}
                <Row>
                    <Col>
                        
                    </Col>
                </Row>
            </Container>
        );  

        return(
            <div id="platts-published-totals-view" className="moduleContainer alternative">                
                {this.props.horizontal ? horizontalView : verticalView}
                {/*<div className="alignRight">
                    <Button bsStyle="link" href="/plattsdetails">View details</Button>
                </div>*/}
            </div>
        );
    }
}

export default PlattsPublishedTotalsView; 