/* Platts Labs API */
//const PATH_BASE = 'http://localhost';

var config = null;
var PATH_BASE = null;
var QUERY_AUTH = null;
var QUERY_OPERATOR = null;
var QUERY_REGULATOR = null;
var QUERY_PUBLISHER = null;
var QUERY_GLOBAL = null;
var QUERY_ADMIN = null;
var QUERY_RESET = null;



export const environmentList = {
    LOCALHOST :  'localhost:3000',
    DEVFL: 'devfl',
    DEV: 'dev',
    QA: 'qa',
    PROD: 'fujairahstats',
    DEVFLCF: 'dht2v7o6rle2r'
};

export function cryptoRandom() {
    const typedArray = new Uint8Array(1);
    const randomValue = crypto.getRandomValues(typedArray)[0];
    const randomFloat = randomValue / Math.pow(2, 8);
    return randomFloat;
  }

export function LoadEnvironmentValues(FujlEnv) {
    
    switch (FujlEnv) {
        case environmentList.DEVFL:
            config = require('../env.json')[environmentList.DEVFL]
            break;
        case environmentList.DEV:
            config = require('../env.json')[environmentList.DEV]
            break;
        case environmentList.QA:
            config = require('../env.json')[environmentList.QA]
            break;
        case environmentList.PROD:
            config = require('../env.json')[environmentList.PROD]
            break;
        default:
            config = require('../env.json')[environmentList.DEVFL]
            break;
    }
    
    if (config !== null) {
        PATH_BASE = config.API_URI;
        QUERY_AUTH = config.QUERY_AUTH;
        QUERY_OPERATOR = config.QUERY_OPERATOR;
        QUERY_REGULATOR = config.QUERY_REGULATOR;
        QUERY_PUBLISHER = config.QUERY_PUBLISHER;
        QUERY_GLOBAL = config.QUERY_GLOBAL;
        QUERY_ADMIN = config.QUERY_ADMIN;
        QUERY_RESET = config.QUERY_RESET;
    }
    return config;
}



const getCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
}


/* Constants */
export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

/* Theme */
export const LIGHT_COLOUR = '#08c3a5';
export const MIDDLE_COLOUR = '#f36c35';
export const HEAVY_COLOUR = '#c40067';
export const EMPTY_COLOUR = '#1d1e29';

/* Enums */
export const STATUS = {
    SUBMITTED: 'SUBMITTED',
    OPEN: 'OPEN',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    PUBLISH: 'PUBLISH'
}

export const USER_TYPE = {
    GLOBAL: 0,
    OPERATOR: 1,
    REGULATOR: 2,
    PUBLISHER: 3,
    ADMIN: 4,
    AUTH: 5,
    DOWNLOAD: 6,
    RESET: 7
}

/* Helper Methods */
export function getAPIPath(userType) {
    switch (userType) {
        case USER_TYPE.GLOBAL:
            return PATH_BASE + QUERY_GLOBAL;
        case USER_TYPE.OPERATOR:
            return PATH_BASE + QUERY_OPERATOR;
        case USER_TYPE.REGULATOR:
            return PATH_BASE + QUERY_REGULATOR;
        case USER_TYPE.PUBLISHER:
            return PATH_BASE + QUERY_PUBLISHER;
        case USER_TYPE.ADMIN:
            return PATH_BASE + QUERY_ADMIN;
        case USER_TYPE.AUTH:
            return PATH_BASE + QUERY_AUTH;
        case USER_TYPE.RESET:
            return PATH_BASE + QUERY_RESET;
        default:
            break;
    }
}

export function headersWithMethod(method, body) {
    var jwtToken = getCookie("jwt");
    return ({
        method: method,
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': jwtToken
        },
        body: body
    });
}

export function fetchAPI(path, attributes) {
    return new Promise((resolve, reject) => {
        fetch(path, attributes).then((response) => {
            if (response.status === 401) {
                 console.log('After error 401 - ', response);
                 window.location.href = "/logout";
                 reject();
            }
            resolve(response);
        }).catch((error) => {
            console.log('reject error- ', error);
            reject(error);
        })
    });
}

