import React, {Component} from 'react';

// Css
import './CurrentTotalView.css';

// Modules 
import LevelsPieChart from '../../Modules/LevelsPieChart';

class CurrentTotalView extends Component {

    render() {        

        if (this.props.data == null) {
            return null;
        }

        var lightPieChart, middlePieChart, heavyPieChart = null;         

       if (this.props.data.light !== undefined && this.props.data.light.capacity !== undefined) {                       
            lightPieChart = (                
                <div className="pieChartContainer">
                    <LevelsPieChart level={this.props.data.light.value} volume={this.props.data.light.capacity} commodityType="light" />
                </div>)
        }

        if (this.props.data.middle !== undefined && this.props.data.middle.capacity !== undefined) {
            middlePieChart = (
                <div className="pieChartContainer">
                    <LevelsPieChart level={this.props.data.middle.value} volume={this.props.data.middle.capacity} commodityType="middle" />
                </div>)
            }

        if (this.props.data.heavy !== undefined && this.props.data.heavy.capacity !== undefined) {
            heavyPieChart = (
                <div className="pieChartContainer">
                    <LevelsPieChart level={this.props.data.heavy.value} volume={this.props.data.heavy.capacity} commodityType="heavy" />
                </div>)
        }

        return(
            <div className="moduleContainer">
                <h2>Your current levels*</h2>
                
                { lightPieChart }
                { middlePieChart }
                { heavyPieChart }
                

                <i>* in thousands of barrels</i>
            </div>     
        );        
    }
}

export default CurrentTotalView; 