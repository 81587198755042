import React, { Component } from 'react';
import { Button,Form,Col } from 'react-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import './PublishDateView.css';
import { confirmAlert } from 'react-confirm-alert';
import { createOffset } from '../../helpers';

// Services 
import { publisherService } from '../../Services/publisherService.js';

class PublishDateView extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            selectedDay: null,            
            aggregateAsset: {
                currentPeriod: null
            }
        };

        this.setLoading = this.setLoading.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.getCurrentAsset = this.getCurrentAsset.bind(this);

        this.getCurrentAsset();
    }

    setLoading(isLoading) {
        this.props.loadingHandler(isLoading);
    }
    
    handleDayChange(day) {
        this.setState({ selectedDay: day });
    }

    getCurrentAsset() {
        this.setLoading(true);
        publisherService.getAggregatedAsset().then((asset) => {
            this.setLoading(false);
            console.log('Before current asset call');
            if (asset != null && asset.publishDetails != null) {
                this.setState({
                    aggregateAsset: {
                        currentPeriod: asset.publishDetails.currentPeriod
                    }
                })
            }
        }).catch((error) => {
            this.setLoading(false);
            console.log(error);
        })
    }

    setPublishDate() {
        if (this.state.selectedDay == null) {
            alert('Please select a date');
            return;
        }

        Date.prototype.withoutTime = function () {
            var d = new Date(this);
            d.setHours(0, 0, 0, 0);
            return d;
        }

        var d = new Date();        
        if (this.state.selectedDay.withoutTime() < d.withoutTime()) {
            alert('Date cannot be set in the past');
            return;
        }

        console.log('this.state.aggregateAsset.currentPeriod- ', this.state.aggregateAsset.currentPeriod);
        var currentPublishDate = this.state.aggregateAsset.currentPeriod !== undefined ? new Date(this.state.aggregateAsset.currentPeriod).withoutTime() : this.state.aggregateAsset.currentPeriod;
        var setPublishDateNow = this.state.selectedDay.withoutTime();
        if(currentPublishDate !== undefined && currentPublishDate.toISOString() === setPublishDateNow.toISOString()){
            confirmAlert({
                title: 'Confirm to reset publish date',
                message: 'You are trying to set the same publish date again. Please note that all operators previous submissions for given publish date will be ignored and inventory totals needs to be submitted again. Do you want to proceed this change?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            var newDt = new Date(this.state.aggregateAsset.currentPeriod);
                            newDt.setMinutes(newDt.getMinutes() + 5);
                            this.setLoading(true);
                            const timezoneOffset = createOffset(newDt)
                            console.log('Offset', timezoneOffset)
                            const newPeriodReset = moment(newDt).utc().local().format('YYYY-MM-DD HH:mm:ss')
                            console.log('newPeriodReset:  ', newPeriodReset)
                            publisherService.setNewPeriod(newPeriodReset, timezoneOffset).then((response) => {
                                this.setLoading(false);
                                alert("New publish date set to: " + moment(newDt).format('DD MMM YYYY'));
                                this.getCurrentAsset();
                            }).catch((error) => {
                                this.setLoading(false);
                                alert("Unable to set the publish date");
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: null
                    }
                ],
                closeOnEscape: false,
                closeOnClickOutside: false
            }); 
        } else{
            this.setLoading(true);
            const timezoneOffset = createOffset(this.state.selectedDay)
            console.log('Offset', timezoneOffset)
            const newPeriod = moment(this.state.selectedDay).utc().local().format('YYYY-MM-DD HH:mm:ss');
            console.log('this.state.selectedDay: ', newPeriod);
            publisherService.setNewPeriod(newPeriod, timezoneOffset).then((response) => {
                this.setLoading(false);
                alert("New publish date set to: " + moment(this.state.selectedDay).format('DD MMM YYYY'));
                this.getCurrentAsset();
            }).catch((error) => {
                this.setLoading(false);
                alert("Unable to set the publish date");
            })
        }
    }

    render() {
        const modifiers = {
            fridays: { daysOfWeek: [5] }            
        };
        const modifiersStyles = {            
            fridays: {
                color: '#ffffff',
                backgroundColor: '#ff0000',
            },
        };

        return(
            <div className="container">
                <div className="currentDateContainer">
                    <h4>Current Publish Date: <Moment format="DD MMM YYYY">{this.state.aggregateAsset.currentPeriod}</Moment></h4>
                </div>

                <div className="publishDateContainer">
                    <h2>Select publish date</h2>

                    <p>Select the next submission due date. <br /> <b>NB!</b> Setting the publish date will set the status on all operator assets to OPEN, allowing them to submit.</p>
                    <Form>
                        <Form.Group>
                            <Col sm={2} className="publisherDate">
                    <DatePicker selected={this.state.selectedDay}  dateFormat="yyyy-MM-dd"
                    placeholderText="YYYY-MM-DD"
                    onChange={this.handleDayChange}
                    />
                            </Col>
                            <Col sm={2} className="publisherDate">
                                <Button variant="success" onClick={this.setPublishDate.bind(this)}>Set Publish Date</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                 </div>
            </div>
        );
    }
}

export default PublishDateView; 