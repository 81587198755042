import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

// CSS 
import './AlertModal.css';

class AlertModal extends Component {

    constructor(props) {
        super(props); 
        
        this.state = {
            showModal: true,
            retryAction: null
        };

        this.closeModal = this.closeModal.bind(this)
    }

    closeModal() {
        this.props.modalAction();        
    }
    
    render () {
        const modalInstance = (
            <div className="static-modal">
                <Modal animation={false} show={this.state.showModal} onHide={this.closeModal} className="alertModal">
                    <Modal.Header>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal} bsStyle="success" className="okButton">OK</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );

        return (modalInstance)        
    }

} 

export default AlertModal;