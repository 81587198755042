import React, {Component} from 'react';
import Moment from 'react-moment';

// Css 
import './ReminderNotice.css';

class ReminderNotice extends Component {

    render() {
        return(
            <div id="reminder-notice" className="reminderNoticeContainer">
                <span>Current Submission Date: <b><Moment format="DD MMMM YYYY">{this.props.date}</Moment></b></span>
            </div>
        );
    }

}

export default ReminderNotice;