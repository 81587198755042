import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';

// Modules 
import TotalsPieChart from '../../Modules/TotalsPieChart';

// CSS 
import './TotalsPieChartView.css';

class TotalsPieChartView extends Component {

    constructor(props) {
        super(props);

        this.state ={
            data: null
        }

        this.formatData = this.formatData.bind(this);
    }
   

    formatData(data, type) {
        
        let lightData = [];
        let middleData = [];
        let heavyData = [];

        data.map((item, i) => {
            lightData.push({name: item.organization.name, value: item.lightAmount});
            middleData.push({name: item.organization.name, value: item.middleAmount});
            heavyData.push({name: item.organization.name, value: item.heavyAmount});
            return;
        });
        
        switch (type) {
            case "light":
                return lightData
            case "middle":
                return middleData
            case "heavy":
                return heavyData
            default:
                break;
        }
    }

    render() {

        if (this.props.data == null) {
            return null;         
        }        
        
        if (this.props.data.filter(item => item.status == 'OPEN').length === this.props.data.length) {
            return null;
        }

        return(
            <div id="totals-pie-chart" className="moduleContainer totalPieChartViewContainer">   
                <h2>Current Totals Distribution</h2>                             
                <Container>
                    <Row>
                        <Col sm={4} className="center-align">                            
                            <TotalsPieChart commodityType="light" data={this.formatData(this.props.data, "light")}/>                            
                            <h3>Light Distillates</h3>
                        </Col>
                        <Col sm={4} className="center-align">                            
                            <TotalsPieChart commodityType="middle" data={this.formatData(this.props.data, "middle")}/>
                            <h3>Middle Distillates</h3>
                        </Col>
                        <Col sm={4} className="center-align">                            
                            <TotalsPieChart commodityType="heavy" data={this.formatData(this.props.data, "heavy")}/>
                            <h3>Heavy Distillates</h3>
                        </Col>
                    </Row>            
                </Container>
            </div>
        );
    }

}

export default TotalsPieChartView;