import React, { Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';

// CSS
import './OperatorSubmissionSummary.css';


class OperatorSubmissionSummary extends Component {

    render() {

        if (this.props.data == null) {
            return (null);
        }

        return(
            <div className="moduleContainer alternative">
                <Container>
                    <Row>
                        <Col sm={6}>
                            <h1><Moment format="DD MMMM YYYY">{this.props.data.currentPeriod}</Moment></h1>                                                
                            {/* <a href="/submissions/details" className="whiteText">View submission details</a> */}
                        </Col>
                        <Col sm={6}>
                            <div className="alignRight">
                                <h3>{this.props.data.submittedCount} out of {this.props.data.totalCount} submissions received</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

}

export default OperatorSubmissionSummary; 