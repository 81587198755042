import React, { Component } from 'react';
// import { Navbar, NavItem, Nav, Button } from 'react-bootstrap';
//import { BrowserRouter as Router, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchLoginState, clickLogout } from './redux/auth/actions/auth';
const { detect } = require('detect-browser');
// CSS 
import './NavBar.css';

// Views
import ModulePreview from './Views/Temp';
import LoginView from './Views/LoginView';
import ActivateView from './Views/ActivateView';
import DashboardView from './Views/DashboardView';
import ResetPasswordView from './Views/ResetPasswordView';

// Services 
import { globalService } from './Services/globalService.js';


const propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

class NavBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      authenticated: false, 
      user: null,       
      submissionDueDate: null
    };
    
    this.setDataModel = this.setDataModel.bind(this);
    this.populateData = this.populateData.bind(this);
    //this.getCurrentPeriod();
  }

  componentDidMount() {    
    this.populateData();
  }

  populateData() {
    this.setState({
      error: false,
      errorMessage: null
    });    

    if (window.localStorage.FujairahAppStorage) {
      var user = JSON.parse(window.localStorage.getItem('FujairahAppStorage'));
      if (user) {
        this.setState({
          authenticated: true, 
          user: user
        });
      } else {
        this.setState({
          authenticated: false, 
          user: null
        });
      }
    }
  }  

  displayError(error) {    
    this.setState({
      error: true,
      errorMessage: error.message
    });    
  }

  setDataModel(result, query) {
    const {data} = this.state;
    data['commodity'] = result;

    this.setState({
      data: result,
      isLoading: false
    });
  }

  getCurrentPeriod() {
    globalService.getCurrentPeriod().then((response) => {
      console.log(response);
    });
  }

  // Cognito Auth 
  componentWillMount() {
    const { pathname } = document.location;
    this.props.dispatch(fetchLoginState({ pathname: pathname }));
  }

  componentWillReceiveProps(nextProps) {
    const { auth } = nextProps;
    if (auth) {
      this.guestWillTransfer(auth);
    }
  }

  guestWillTransfer(auth) {
    const { pathname } = document.location;    
    
    if (!auth.isLoggedIn && (pathname.indexOf('/temp') !== -1)) {
      return 
    }
    if (pathname === '/logout') {
      auth.isLoggedIn = false;
      this.props.dispatch(clickLogout());
      this.transferPage('/login', 0);
      return;
    }

    if (!auth.isLoggedIn && (pathname.indexOf('/activate') !== -1)) {
      return;
    } else if (pathname === '/reset') {
      return;
    }else if (!auth.isLoggedIn && pathname !== '/login') {
      this.transferPage('/login', 0);
    } else if (auth.isLoggedIn && (pathname === '/login' || (pathname.indexOf('/activate') !== -1))) {            
      this.transferPage('/', 0);
    }
  }

  transferPage(path, time) {
    setTimeout(function() {
      document.location.href = path;
    }, time);
  }

  render() {
    const { auth } = this.props;
    const browser = detect();
    
     const RouteWithAuth = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props => {
          {/*if (auth.pathname && props.location.pathname !== auth.pathname) {
            this.props.dispatch(
              fetchLoginState({ pathname: props.location.pathname, auth: auth })
            );
          }*/}
          return (auth.isLoggedIn && rest.private) || !rest.private ? (
            <Component {...props} />
          ) : (
            <div>Loading</div>
          );
        }}
      />
    );
   
    return (      
      <div className="mainContainer">
         {
         (browser && browser.name === 'ie') ?
         <div className="browser-notification"><span>Unfortunately Internet Explorer is not supported. Please try using another browser.</span></div>
         :
         <div>
          <Switch>            
            <RouteWithAuth path="/temp" exact component={() => <ModulePreview />} />
            <RouteWithAuth path="/activate/:email" exact component={(props) => <ActivateView {...props}/>} />
            <RouteWithAuth path="/reset" exact component={(props) => <ResetPasswordView {...props}/>} />
            <RouteWithAuth path="/login" exact component={() => <LoginView />} />
            <RouteWithAuth path="/" exact private component={() => <DashboardView control={this.props.auth.user["custom:organisationType"]} />} />
            <RouteWithAuth path="/publisher/publishdate" exact private component={() => <DashboardView control="publishdate"/>} />                                                
            <RouteWithAuth path="/submission/history" exact private component={() => <DashboardView control="submissionhistory"/>} />    
            {this.props.auth.user !== undefined && this.props.auth.user['custom:admin'] !== null && this.props.auth.user['custom:admin'] === '0' ? <RouteWithAuth path="/admin/users" exact private component={() => <DashboardView/>}/> : null } 
            {this.props.auth.user !== undefined && this.props.auth.user['custom:admin'] !== null && this.props.auth.user['custom:admin'] === '1' ? <RouteWithAuth path="/admin/users" exact private component={() => <DashboardView control="admin" manageUsers={true}/>}/> : null }                       
            {this.props.auth.user !== undefined && this.props.auth.user['custom:admin'] !== null && this.props.auth.user['custom:organisationType'] === 'operator' && this.props.auth.user['custom:admin'] === '1' ? <RouteWithAuth path="/admin/capacity" exact private component={() => <DashboardView control="admin" manageUsers={false}/>} /> : <DashboardView/> }                        
          </Switch>             
        </div>}
        </div>
    );    
  }
}

NavBar.propTypes = propTypes;

function select({ auth }) {
  return { auth };
}

export default withRouter(connect(select)(NavBar));
