import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Modules 
import RegulatorSubmissionDataTable from '../../Modules/RegulatorSubmissionDataTable';

// Services 
import { regulatorService } from '../../Services/regulatorService.js';

import './SubmissionHistoryView.css';

class SubmissionHistoryView extends Component {   
    constructor(props) {
        super(props);

        this.state = {
            historicData: null,             
            loading: true
        }

        this.setLoading = this.setLoading.bind(this);
        this.getSubmissionsData = this.getSubmissionsData.bind(this);        
        this.getSubmissionsData()        

    }

    setLoading(isLoading) {
        this.props.loadingHandler(isLoading);
    }

    getSubmissionsData() {
        this.setLoading(true);
        regulatorService.getHistoricalSubmissions().then((dataResponse) => {
            this.setState({
                historicData: dataResponse
            });
            this.setLoading(false);
        });
    }

    render() {        
        return(
            <Container>
                <Row>
                    <Col>                        
                        <div className="moduleContainer">
                            <RegulatorSubmissionDataTable  data={this.state.historicData} title="Submission History" loadingHandler={this.setLoading} />
                        </div> 
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default SubmissionHistoryView;