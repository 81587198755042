import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Views 
import CurrentTotalView from '../CurrentTotalView';
import PlattsPublishedTotalsView from '../PlattsPublishedTotalsView';

// Modules 
import LevelsBarChart from '../../Modules/LevelsBarChart';
import OperatorSubmissionDataTable from '../../Modules/OperatorSubmissionDataTable';

// Services 
import {operatorService} from '../../Services/operatorService.js';

class OperatorView extends Component {

    constructor(props) {
        super(props); 

        this.state = {
            totalsData: null, 
            latestTotals: null, 
            operatorSubmissionsData: null, 
            operatorAssets: null            
        };

        this.setLoading = this.setLoading.bind(this);

        this.getTotals();     
        this.getOperatorSubmissionTableData();        
        this.getAssets();
        this.getLatestTotals();
    }

    setLoading(isLoading) {
        this.props.loadingHandler(isLoading);
    }

    getTotals() {
        this.setLoading(true);
        operatorService.getTotalsData().then((response) => {            
            this.setState({
                totalsData: response
            });            
            this.setLoading(false);
        });
    }

    getAssets() {
        this.setLoading(true);
        operatorService.getAssets().then((assets) => {
            this.setState({                
                operatorAssets: assets
            });
            this.setLoading(false);
        })
    }

    getOperatorSubmissionTableData() {
        this.setLoading(true);
        operatorService.getOperatorSumbissions().then((response) => {
            this.setState({
                operatorSubmissionsData: response
            });
            this.setLoading(false);
        });
    }

    getLatestTotals() {
        this.setLoading(true);
        operatorService.getLatestTotals().then((currentSubmission) => {    
            if(currentSubmission){       
                this.setState({                    
                    latestTotals: {
                        light: {
                            value: currentSubmission.light.amount, 
                            capacity: currentSubmission.light.capacity
                        }, 
                        middle: {
                            value: currentSubmission.middle.amount, 
                            capacity: currentSubmission.middle.capacity
                        }, 
                        heavy: {
                            value: currentSubmission.heavy.amount, 
                            capacity: currentSubmission.heavy.capacity
                        }, 
                        currentPeriod: currentSubmission.currentPeriod      
                    }
                });
                this.setLoading(false);
                this.props.submissionDateHandler(currentSubmission.currentPeriod);
            }
        });
    }

    render() {
        return(
            <div id="operator-view">                                
                <Container>                    
                    <Row>                        
                        <Col sm={4}>
                            <CurrentTotalView data={this.state.latestTotals}/> 
                        </Col>
                        <Col sm={8}>
                            <LevelsBarChart data={this.state.totalsData} title="Your submissions history"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>                                                        
                            <PlattsPublishedTotalsView horizontal/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <OperatorSubmissionDataTable data={this.state.operatorAssets} title="Your current submission" showHistory={false}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <OperatorSubmissionDataTable data={this.state.operatorSubmissionsData} title="Your submissions history" showHistory={true}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                </Container>                
            </div>
        );
    }
}

export default OperatorView; 