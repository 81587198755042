import React, { Component } from 'react';

import { Sector, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import {LIGHT_COLOUR, MIDDLE_COLOUR, HEAVY_COLOUR, EMPTY_COLOUR } from '../../constants';

// CSS 
import './TotalsPieChart.css';

class TotalsPieChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0, 
            data: null, 
            colors: null,
            commodityType: this.props.commodityType
        }
        
        this.onPieEnter = this.onPieEnter.bind(this);
        this.configureChartColours = this.configureChartColours.bind(this);
        this.configureChartColours();        
    }
    
    onPieEnter(data, index) {
        this.setState({
            activeIndex: index,
        });
    }

    componentWillMount() {        
        this.configureChartColours();
    }

    configureChartColours() {
        
        switch (this.state.commodityType) {
            case 'light':                 
                this.setState({
                    colors: [LIGHT_COLOUR, EMPTY_COLOUR]
                })
                break; 
            case 'middle': 
                this.setState({
                    colors: [MIDDLE_COLOUR, EMPTY_COLOUR]
                })
                break; 
            case 'heavy':
                this.setState({
                    colors: [HEAVY_COLOUR, EMPTY_COLOUR]
                })
                break; 
            default: 
                this.setState({
                    colors: [EMPTY_COLOUR, EMPTY_COLOUR]
                })
                break;
        }
    }

    render() {

        if (this.props.data == null) {
            return null;
        }

        const data = this.props.data; 

        const renderActiveShape = (props) => {
            
            const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;            
            return (
                <g>
                    <text x={cx} y={cy + 125} dy={8} textAnchor="middle" fill={fill}>
                        {payload.name}                        
                    </text>
                    <text x={cx} y={cy - 15} dy={7} textAnchor="middle" fill="#fff">{`Lvl ${value}`}</text>
                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#999">
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                    </text>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius + 2}
                        outerRadius={outerRadius - 1}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={innerRadius - 6}
                        outerRadius={innerRadius - 2}
                        fill={fill}
                    />
                    {/*<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
                    <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#fff">{`Lvl ${value}`}</text>
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                    </text>*/}                    
                </g>
            );
        };

        if (this.state.colors == null) {            
            return (null);
        }

        return(
            <div>
                <ResponsiveContainer height={260} className="center">
                    <PieChart>
                        <Pie
                        activeIndex={this.state.activeIndex}
                        activeShape={renderActiveShape} 
                        data={data} 
                        cx={170}
                        cy={115}                             
                        labelLine={true}  
                        innerRadius={80}                      
                        outerRadius={115} 
                        fill="#8884d8"
                        onMouseEnter={this.onPieEnter}
                        dataKey="value"
                        >
                            {
                            data.map((entry, index) => <Cell fill={this.state.colors[index % 1]} strokeWidth={2} stroke="black" key={index}/>)
                        }
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        );
    }

}

export default TotalsPieChart;