import React, { Component } from 'react';
import Moment from 'react-moment';
import { Modal, Button, Form, Col } from 'react-bootstrap';

// Views 
import DataSubmissionProgressView from '../DataSubmissionProgressView';

// CSS 
import './DataSubmissionsView.css';

// Services 
import { operatorService } from '../../Services/operatorService.js';

const percentageThreshold = {
    min: -5.0,
    max: 5.0
};

class DataSubmissionView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showErrors: false,
            showModal: true,
            showProgress: false,
            completeClass: null,
            lightValue: null,
            middleValue: null,
            heavyValue: null,
            isValid: true,
            validationMessage: '',
            lightCapacity: 0,
            middleCapacity: 0,
            heavyCapacity: 0,
            previousLightValue: 0,
            previousMiddleValue: 0,
            previousHeavyValue: 0,
            currentPeriod: null,
            needsConfirmation: false,
            hasUpdatedValues: false,
            fetchingValues: true
        };

        this.getLatestTotals();

    }

    getLatestTotals() {
        operatorService.getOperatorPreviousSubmission().then((previousSubmission) => {
            operatorService.getAssets().then((currentSubmission) => {
            this.setState({
                previousLightValue: previousSubmission !== undefined && previousSubmission.light ? previousSubmission.light.amount : 0,
                previousMiddleValue: previousSubmission !== undefined && previousSubmission.middle ? previousSubmission.middle.amount : 0,
                previousHeavyValue: previousSubmission !== undefined && previousSubmission.heavy ? previousSubmission.heavy.amount : 0,
                currentPeriod: currentSubmission.length > 0 ? currentSubmission[0].currentPeriod : null,
                lightCapacity: currentSubmission.length && currentSubmission.length > 0 ? currentSubmission[0].lightCapacity : null,
                middleCapacity: currentSubmission.length && currentSubmission.length > 0 ? currentSubmission[0].middleCapacity : null,
                heavyCapacity: currentSubmission.length && currentSubmission.length > 0 ? currentSubmission[0].heavyCapacity : null,
                fetchingValues: false
            });
        });
    });
}

    closeModal() {
        this.props.completAction();
    }

    submitAction() {
        this.resetValidation()
        if (this.validateData()) {
            this.setState({ showProgress: true });
             operatorService.submitCommodityAmount(this.props.auth.user.email, 
                this.state.lightValue === null ? 0 : parseInt(this.state.lightValue), 
                this.state.middleValue === null ? 0 : parseInt(this.state.middleValue), 
                this.state.heavyValue === null ? 0 : parseInt(this.state.heavyValue));
        }
    }

    completeSubmission() {
        this.setState({
            completeClass: 'complete'
        });
    }
    closeModalPopup(){
        this.closeModal()
    }

    validateData() {

        if (this.state.needsConfirmation && !this.state.hasUpdatedValues) {
            return true;
        }

        if ((this.state.lightValue == null && this.state.lightCapacity > 0) || this.state.lightValue < 0) {
            this.setState({
                isValid: false,
                validationMessage: 'Please enter a light distillates value'
            });
            return false;
        }

        if ((this.state.middleValue == null && this.state.middleCapacity > 0) || this.state.middleValue < 0) {
            this.setState({
                isValid: false,
                validationMessage: 'Please enter a middle distillates value'
            });

            return false;
        }

        if ((this.state.heavyValue == null && this.state.heavyCapacity) || this.state.heavyValue < 0) {
            this.setState({
                isValid: false,
                validationMessage: 'Please enter a heavy distillates value'
            });
            return false;
        }

        if (this.state.lightCapacity === 0 && this.state.lightValue > 0) {
            this.setState({
                isValid: false,
                validationMessage: 'Your light distillates capacity is currently set to 0. Please update your current light distillates capacity.'
            });
            return false;
        }

        if (this.state.middleCapacity === 0 && this.state.middleValue > 0) {
            this.setState({
                isValid: false,
                validationMessage: 'Your middle distillates capacity is currently set to 0. Please update your current middle distillates capacity.'
            });
            return;
        }

        if (this.state.heavyCapacity === 0 && this.state.heavyValue > 0) {
            this.setState({
                isValid: false,
                validationMessage: 'Your heavy distillates capacity is currently set to 0. Please update your current heavy distillates capacity.'
            });
            return;
        }

        if (this.state.lightValue > this.state.lightCapacity) {
            this.setState({
                isValid: false,
                validationMessage: 'Your light distillates amount is greater than light distillates capacity. Please check the values.'
            });
            return false;
        }

        if (this.state.middleValue > this.state.middleCapacity) {
            this.setState({
                isValid: false,
                validationMessage: 'Your middle distillates amount is greater than middle distillates capacity. Please check the values.'
            });
            return false;
        }

        if (this.state.heavyValue > this.state.heavyCapacity) {
            this.setState({
                isValid: false,
                validationMessage: 'Your heavy distillates amount is greater than heavy distillates capacity. Please check the values.'
            });
            return false;
        }

        if (this.state.lightValue === this.state.previousLightValue ||
            this.state.middleValue === this.state.previousMiddleValue ||
            this.state.heavyValue === this.state.previousHeavyValue) {

            this.setState({
                isValid: false,
                needsConfirmation: true,
                validationMessage: "We've detected that a number you've entered is the same as previous submission. Please confirm your submission by clicking the confirm button below."
            });
            return false;

        }

        if (!this.isWithinThreshold(this.state.lightValue, this.state.previousLightValue) ||
            !this.isWithinThreshold(this.state.middleValue, this.state.previousMiddleValue) ||
            !this.isWithinThreshold(this.state.heavyValue, this.state.previousHeavyValue)) {

            this.setState({
                isValid: false,
                needsConfirmation: true,
                validationMessage: "We've detected a bigger change than expected in the totals submitted. Please confirm your submission by clicking the confirm button below."
            });
            return false;

        }
        this.resetValidation();

        return true;
    }

    isWithinThreshold(submittedTotal, previousTotal) {
        if (!previousTotal && previousTotal <= 0) {
            return true;
        }

        let calculatedPercentage = ((submittedTotal / previousTotal) * 100) - 100;
        if (calculatedPercentage > percentageThreshold.max || calculatedPercentage < percentageThreshold.min) {
            return false;
        }

        return true;
    }

    handleLightChange(e) {
        if (this.state.lightCapacity === 0) {
            alert('Your light distillates capacity is currently set to 0. Please update your current light distillates capacity.');
            e.target.value = null;
            return;
        } else {
            this.setState({
                lightValue: e.target.value === "" ? null : e.target.value,
                hasUpdatedValues: this.state.needsConfirmation ? true : false
            });
        }
    }

    handleMiddleChange(e) {

        if (this.state.middleCapacity === 0) {
            alert('Your middle distillates capacity is currently set to 0. Please update your current middle distillates capacity.');
            e.target.value = null;
            return;
        }
        this.setState({
            middleValue: e.target.value === "" ? null : e.target.value,
            hasUpdatedValues: this.state.needsConfirmation ? true : false
        });
    }

    handleHeavyChange(e) {

        if (this.state.heavyCapacity === 0) {
            alert('Your heavy distillates capacity is currently set to 0. Please update your current heavy distillates capacity.');
            e.target.value = null;
            return;
        }

        this.setState({
            heavyValue: e.target.value === "" ? null : e.target.value,
            hasUpdatedValues: this.state.needsConfirmation ? true : false
        });
    }

    resetValidation() {
        this.setState({
            isValid: true,
            needsConfirmation: false,
            validationMessage: null,
            hasUpdatedValues: false
        });
    }

    render() {

        var modalBody, modalFooter, error;

        if (!this.state.isValid && this.state.validationMessage != null) {
            error = (
                <div className="validation">{this.state.validationMessage}</div>
            )
        } else {
            error = null;
        }

        if (this.state.showProgress) {
            modalBody = (<DataSubmissionProgressView completeAction={this.completeSubmission.bind(this)} 
            closePopup={this.closeModalPopup.bind(this)} />);
            modalFooter = (null);
            

        } else {
            const submissionForm = (
                <Form>
                    <Form.Group>
                        <Col sm={4}>
                            <Form.Label>Light Distillates *</Form.Label>

                            <Form.Control type="number" placeholder="" onChange={this.handleLightChange.bind(this)} size="lg" value={this.state.lightValue} />
                        </Col>

                        <Col sm={4}>
                            
                            <Form.Label>Middle Distillates *</Form.Label>

                            <Form.Control type="number" placeholder="" onChange={this.handleMiddleChange.bind(this)} size="lg" value={this.state.middleValue} />
                        </Col>

                        <Col sm={4}>
                            
                            <Form.Label>Heavy Distillates *</Form.Label>

                            <Form.Control type="number" placeholder="" onChange={this.handleHeavyChange.bind(this)} size="lg" value={this.state.heavyValue} />
                        </Col>
                    </Form.Group>

                    <Form.Group>
                        <Col sm={4} className="alignRight col-sm-offset-8">
                            <i>* in thousands of barrels</i>
                        </Col>
                    </Form.Group>
                </Form>
            );

            modalBody = (
                <div>
                    <h3>Submission for <span><Moment format="DD  MMMM YYYY">{this.state.currentPeriod}</Moment></span></h3>
                    {error}
                    {this.state.fetchingValues == true ? <span>Loading...</span> : submissionForm}
                    <div className="previousSubmissions">
                        <h4>Your previous submitted levels:</h4>
                        <span className="commodityValue light">{this.state.previousLightValue}</span><span className="commodityValue middle">{this.state.previousMiddleValue}</span><span className="commodityValue heavy">{this.state.previousHeavyValue}</span>
                    </div>
                </div>
            );

            modalFooter = (
                <Modal.Footer>
                    <Form.Group>
                        <Col sm={6}>
                            <Button onClick={this.closeModal.bind(this)} className="submitButton cancel">Cancel</Button>
                        </Col>
                        <Col sm={6}>
                            <Button type="submit" onClick={this.submitAction.bind(this)} variant={this.state.needsConfirmation ? "success" : "primary"} className="submitButton">{this.state.needsConfirmation ? "Confirm totals" : "Submit"}</Button>
                        </Col>
                    </Form.Group>
                </Modal.Footer>
            );
        }


        return (
            <div className="data-submission-container">
                <Modal animation={false} show={this.state.showModal} onHide={this.closeModal.bind(this)} 
                    className={"submissionModal " + (this.state.completeClass === 'complete' ? "modalheight" : "")} width={900}
                backdrop="static"
                keyboard={false}>
                    <Modal.Header className={this.state.completeClass}>
                        <Modal.Title>
                            <span>This is a secure 256-bit SSL Encrypted submission</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalBody}
                    </Modal.Body>
                    {modalFooter}
                </Modal>
            </div>);
    }
}

export default DataSubmissionView;
