import React, {Component} from 'react'; 
import { Table } from 'react-bootstrap';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip'

// CSS 
import './OperatorSubmissionDataTable.css';

// Modules 
import SubmissionStatusIcon from '../../Modules/SubmissionStatusIcon';

class OperatorSubmissionDataTable extends Component {

    constructor(props) {
        super(props); 

        this.state = {
        result: props.data
        };
    }
    
    render() {

        if (this.props.data == null || this.props.data === '') {
           return null;
        }

        var title = null; 
        if (this.props.title != null) {
            title = (<h2>{this.props.title}</h2>);
        }

        return(
            <div className="moduleContainer">
                { this.props.title == null ? null : title }          
                
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Submitted Date</th>
                            <th>Publish Date</th>
                            <th>Submitted by</th>
                            <th>Light</th>
                            <th>Middle</th>
                            <th>Heavy</th>
                            {this.props.showHistory ? null : <th>Status</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.data.map((item, i) =>
                            <tr key={i}>
                                <td><Moment format="DD MMM YYYY HH:mm">{item.submittedDate ? item.submittedDate : item.currentPeriod}</Moment></td>
                                <td><Moment format="DD MMM YYYY">{item.currentPeriod}</Moment></td>
                                <td>{item.submittedBy.userName} ({item.submittedBy.ipAddress})</td>
                                <td>{item.lightAmount}</td>
                                <td>{item.middleAmount}</td>
                                <td>{item.heavyAmount}</td>
                                {this.props.showHistory ? null : <td className="center-content"><SubmissionStatusIcon status={item.status} regulatorComment={item.regulatorComment}/></td>}                      
                            </tr>
                        )}              
                        </tbody>
                    </Table>    
                    
                {/*{this.props.data.length === 10 && 
                    <a href="/submissions">View all submissions</a>
                }*/}
                <ReactTooltip />
            </div> 
        );
    }

}

export default OperatorSubmissionDataTable;