import React, {Component} from 'react';
import { Navbar, Nav, Button,NavDropdown,Container,DropdownButton,Dropdown} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clickLogout } from '../../redux/auth/actions/auth';

// Views 
import DataSubmissionView from '../../Views/DataSubmissionView';
import RegulatorView from '../../Views/RegulatorView';
import AdminView from '../../Views/AdminView';
import OperatorView from '../../Views/OperatorView';
import PlattsView from '../../Views/PlattsView';
import PublishDateView from '../../Views/PublishDateView';
import FooterView from '../../Views/FooterView';
import SubmissionHistoryView from '../../Views/SubmissionHistoryView';

// Modules
import ReminderNotice from '../../Modules/ReminderNotice';
import Loading from '../../Modules/Loading';

// Services 
import {operatorService} from '../../Services/operatorService.js';

const propTypes = {
  dispatch: PropTypes.func.isRequired
};

class DashboardView extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            user: null, 
            showSubmissionForm: false,
            showSubmissionButton: false, 
            submissionDate: null, 
            loading: false, 
            activeKey: 1
        }        

        this.handleSignOut = this.handleSignOut.bind(this);        
        this.canSubmitTotals = this.canSubmitTotals.bind(this);
        this.setSubmissionDate = this.setSubmissionDate.bind(this);
        this.setNavigation = this.setNavigation.bind(this);
    }

    componentWillMount() {
        this.setNavigation();
    }

    componentDidMount() {
        this.canSubmitTotals();        
    }

    setNavigation() {
        const { pathname } = document.location;
        let activeKey = 1; 
        if (pathname.includes('/admin')) {
            activeKey = 3
        } else if (pathname.includes('/publisher')) {
            activeKey = 2;
        }

        this.setState({
            activeKey: activeKey        
        })

    }

    openSubmissionForm(e) {
        e.preventDefault();
        this.setState({
            showSubmissionForm: true 
        });
    }

    completeSubmission() {
        this.setState({
            showSubmissionForm: false
        });

        window.location.href = window.location.href;
    }
    
    handleSignOut(e) {
        e.preventDefault();
        this.props.dispatch(clickLogout());
    }
    
    setSubmissionDate(date) {
        this.setState({
            submissionDate: date
        });
    }

    setLoading(isLoading) {                
        this.setState({
            loading: isLoading
        });        
    }

    canSubmitTotals() {
        let user = this.props.auth.user;
        if (user != null && user["custom:organisationType"] === 'operator') {
            operatorService.canSubmitTotals().then((result) => {
            if(result){
                this.setState({
                    showSubmissionButton: result.canSubmitTotals
                })
            }
            }).catch((error) => {
                alert("Unable to submit the Totals");
            })
        }
    }

    render() {
        
        const submitButton = (
            this.props.auth.user["custom:organisationType"] === "operator" && this.state.showSubmissionButton ? <Button variant="primary" onClick={this.openSubmissionForm.bind(this)}>Submit Totals</Button> : null
        )

        const adminLink = (
            this.props.auth.user["custom:admin"] === "1" ? <li role="presentation"  className={this.state.activeKey === 3 ? "active" : ""}>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className="btn-lg btn-link show">
                        ADMINISTRATION <span className="caret"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {this.props.auth.user["custom:organisationType"] === "operator" ?
                            <Dropdown.Item href="/admin/capacity">Capacity</Dropdown.Item> : null}
                        <Dropdown.Item href="/admin/users">Users</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </li> : null
        );

        const publisherLink = (
            this.props.auth.user["custom:organisationType"] === "publisher" ?
            <Nav.Item as="li"> <Nav.Link eventKey={2} href="/publisher/publishdate">Publish Date</Nav.Link> </Nav.Item>: null
        )

        const submissionRemidner = (
            this.state.submissionDate == null ? null : !this.state.showSubmissionButton && this.props.auth.user["custom:organisationType"] === "operator" ? null : <ReminderNotice date={this.state.submissionDate}/>  
        )
        
        var loadView = null;         
        switch (this.props.control) {
            case "admin":
                loadView = (<AdminView {...this.props} loadingHandler={this.setLoading.bind(this)}/>);
                break;
            case "regulator":
                loadView = (<RegulatorView {...this.props} submissionDateHandler={this.setSubmissionDate} loadingHandler={this.setLoading.bind(this)}/>);
                break;
            case "operator": 
                loadView = (<OperatorView {...this.props} submissionDateHandler={this.setSubmissionDate} loadingHandler={this.setLoading.bind(this)}/>);
                break;
            case "publisher": 
                loadView = (<PlattsView {...this.props} loadingHandler={this.setLoading.bind(this)}/>);
                break;
            case "publishdate": 
                loadView = (<PublishDateView {...this.props} loadingHandler={this.setLoading.bind(this)}/>);
                break;
            case "submissionhistory":                
                loadView = (<SubmissionHistoryView {...this.props} loadingHandler={this.setLoading.bind(this)}/>);
                break;
            default:
                loadView = null;
                break;
        }

        return(
            <div>
                <div className="pageWrap">
                    <div>
                        <div className="navigationContainer">
                            {submissionRemidner}
                            <Navbar className='navbar navbar-default'>
                            <Container>
                                <div className="navbar-header"> <img alt="FOIZ" src={require('../../gui/images/logo.png')} className="logo navbar-brand" /></div>
                                <Navbar.Collapse className="navbar-collapse padLeft">
                                    <Nav activeKey={this.state.activeKey} as="ul" className="nav">
                                        <Nav.Item as="li">
                                        <Nav.Link eventKey={1} href="/">Dashboard</Nav.Link>
                                        </Nav.Item>
                                        {publisherLink}
                                        {adminLink}
                                        <Nav.Item as="li">
                                        <Nav.Link eventKey={6} onClick={this.handleSignOut}>Logout</Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                    <Nav className="nav navbar-right">
                                        <div>
                                            <div className="userDetails">
                                                <span>{this.props.auth.user.name} {this.props.auth.user.family_name}</span>
                                            </div>
                                            <div className="profileImage"></div>
                                        </div>
                                    </Nav>
                                    <Nav className="nav navbar-right">
                                        {/* TODO: only show for operators*/}
                                        {submitButton}
                                    </Nav>
                                </Navbar.Collapse>
                                </Container>
                            </Navbar>
                        </div>
                        <div className="results">
                            {loadView}

                            {this.state.showSubmissionForm ? <DataSubmissionView completAction={this.completeSubmission.bind(this)} {...this.props} loadingHandler={this.setLoading.bind(this)} /> : null}
                        </div>
                    </div>
                </div>
                <br /><br />
                <FooterView />
                <Loading visible={this.state.loading} />
            </div>
        )       
    }    
}


DashboardView.propTypes = propTypes;

function select({ auth }) {
  return { auth };
}

export default connect(select)(DashboardView);