import React,{ Component } from 'react';
import {  PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import {LIGHT_COLOUR, MIDDLE_COLOUR, HEAVY_COLOUR, EMPTY_COLOUR } from '../../constants';

// CSS 
import './LevelsPieChart.css';

class LevelsPieChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tankLevel: null,
            tankVolumne: null,
            data: null,
            colors: null, 
            commodityType: null
        }

        this.configureChart = this.configureChart.bind(this)
        this.configureChartColours = this.configureChartColours.bind(this)

    }

    componentWillMount() {
        this.configureChart();
        this.configureChartColours();
    }

    configureChart() {
        
        if (this.props.level == null || this.props.volume == null) {
            return;
        }

        // If the values are the same, set the charts to 100% 
        if (Number(this.props.level) >= Number(this.props.volume))  { 
             this.setState({
                tankLevel: 100,
                tankVolume: 100,
                data: [{name: 'Light', value: 100}]
            });            
        } else {        
            // Calculate volume percentage
            let level = (this.props.level / this.props.volume) * 100; 
            let volume = 100 - level;            
            this.setState({
                tankLevel: level,
                tankVolume: volume,
                data: [{name: 'Light', value: level},{name: 'Total Volume', value: volume}]
            });
        }
    }

    configureChartColours() {

        switch (this.props.commodityType) {
            case 'light': 
                this.setState({
                    colors: [LIGHT_COLOUR, EMPTY_COLOUR]
                })
                break; 
            case 'middle': 
                this.setState({
                    colors: [MIDDLE_COLOUR, EMPTY_COLOUR]
                })
                break; 
            case 'heavy':
                this.setState({
                    colors: [HEAVY_COLOUR, EMPTY_COLOUR]
                })
                break; 
            default: 
                this.setState({
                    colors: [EMPTY_COLOUR, EMPTY_COLOUR]
                })
                break;
        }

    }

    render() {
        if (this.state.data == null) {
            return (null);
        }

        return(
            <div>
                <div className="information">
                    <h3>{this.props.level}</h3>
                    <span>{this.props.commodityType}</span>
                </div>
                <ResponsiveContainer height={160}>
                    <PieChart>
                        <Pie
                            data={this.state.data} 
                            cx={80} 
                            innerRadius={60}
                            outerRadius={80} 
                            fill="#ffffff"
                            paddingAngle={0}
                            margin={0}
                            startAngle={0}
                            stroke={0}
                            dataKey="value"
                            >
                                {
                                    this.state.data.map((entry, index) => <Cell fill={this.state.colors[index % this.state.colors.length]} key={index}/>)
                                }
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                {/*cornerRadius={index === 0 ? 30 : 0} */}
            </div>
        );  
    }

}

export default LevelsPieChart;