import React, { Component } from 'react';
import { Button, Table } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';
import ReactTooltip from 'react-tooltip'
import { confirmAlert } from 'react-confirm-alert';

// Constants
import { STATUS } from '../../constants';

// Modules
import AlertModal from '../../Modules/AlertModal';
import SubmissionStatusIcon from '../../Modules/SubmissionStatusIcon';
import DeclineReasonModal from '../../Modules/DeclineReasonModal';

// CSS
import './SubmissionsDataTable.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

// Services
import { regulatorService } from '../../Services/regulatorService.js';
import { publisherService } from '../../Services/publisherService.js';
import { getStatusClass, getStatusText } from '../../helpers';

class SubmissionsDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: null,
      showConfirmation: false,
      confirmationTitle: null,
      confirmationMessage: null,
      showReasonModal: false,
      aggLightAmount: 0,
      aggMiddleAmount: 0,
      aggHeavyAmount: 0,
      aggLightCapacityAmount: 0,
      aggMiddleCapacityAmount: 0,
      aggHeavyCapacityAmount: 0,
      showCapacityValues: false,
      aggregateStatus: 'OPEN',
      selectedItemIndex: null,
      publisherComment: null,
      alertAction: null
    };

    this.approveSubmission = this.approveSubmission.bind(this);
    this.setAggregateAmounts = this.setAggregateAmounts.bind(this);
    this.getAggregatedTotals = this.getAggregatedTotals.bind(this);
    this.changeAssetState = this.changeAssetState.bind(this);
    this.getAction = this.getAction.bind(this);

    this.getAggregatedTotals();
  }

  componentWillReceiveProps() {
    this.setState({
      result: this.props.data
    });

    if (this.state.result != null) {
      this.setAggregateAmounts();
    }
  }

  changeAssetState(active, i) {
    this.props.loadingHandler(true);
    regulatorService.changeAssetState(this.state.result[i].organization.id, active).then((response) => {

      var data = this.state.result;
      data[i].active = active;

      this.setState({
        result: data,
        showConfirmation: true,
        confirmationTitle: 'Updated Successfully',
        confirmationMessage: 'You have changed the status.',
        alertAction: 'activeInactive'
      });

      this.props.loadingHandler(false);

    });
  }

  approveSubmission(i) {
    this.props.loadingHandler(true);

    regulatorService.approveSubmission(this.state.result[i].organization.id).then((response) => {

      var data = this.state.result;
      data[i].status = STATUS.ACCEPTED;

      this.setState({
        result: data,
        showConfirmation: true,
        confirmationTitle: 'Approved Successfully',
        confirmationMessage: 'Your approval has been submitted.',
        alertAction: 'approve'
      });

       this.props.loadingHandler(false);
    });
  }

  declineSubmission(i) {
    this.setState({
      showReasonModal: true,
      selectedItemIndex: i
    });
  }

  declineSubmissionWithReason(i, reason) {

    this.props.loadingHandler(true);

    regulatorService.declineSubmission(this.state.result[i].organization.id, reason).then((response) => {
      var data = this.state.result;
      data[i].status = STATUS.REJECTED;

      this.setState({
        result: data,
        showConfirmation: true,
        confirmationTitle: 'Declined Successfully',
        confirmationMessage: 'The submission has been declined successfully.',
        selectedItemIndex: null,
        alertAction: 'decline'
      });

      this.props.loadingHandler(false);

      this.closeModalAction();
    });
  }

  revokeSubmission(i) {

    this.props.loadingHandler(true);

    regulatorService.revokeSubmission(this.state.result[i].organization.id).then((response) => {

      var data = this.state.result;
      data[i].status = STATUS.SUBMITTED;

      this.setState({
        result: data,
        showConfirmation: true,
        confirmationTitle: 'Revoked Successfully',
        confirmationMessage: 'Your approval has been revoked.',
        alertAction: 'revoke'
      });
      this.props.loadingHandler(false);
      this.closeModalAction();
    });
  }

  closeModalAction() {
    this.setState({
      showReasonModal: false
    })
  }

  modalAction() {
    this.setState({
      showConfirmation: false
    });
    if(this.state.alertAction !== 'revoke'){
      window.location.href = window.location.href;
    }
  }

  getSubmittedBy(item) {
    if (item.status === "SUBMITTED") {
      return item.organization.submittedBy.userName + ' (' + item.organization.submittedBy.ipAddress + ')';
    }
    return null
  }

  getAction(status, i) {

    if (this.state.result[i].active === false) {
      return(<span>-</span>)
    }

    switch(status) {
      case STATUS.SUBMITTED: 
        return (<div><Button variant="success" onClick={() => this.approveSubmission(i)}>Approve</Button> <Button variant="danger" onClick={() => this.declineSubmission(i)}>Decline</Button></div>)
      case STATUS.ACCEPTED: 
        return (<Button variant="danger" onClick={() => this.revokeSubmission(i)}>Revoke</Button>)
      case STATUS.REJECTED: 
        return (<Button variant="success" onClick={() => this.approveSubmission(i)}>Approve</Button>)
      default: 
        return(<span>-</span>)
    }
  }

  getCommodityStatus(active, i) {
    if (active) {
      return (<Button variant="link" onClick={() => this.changeAssetState(false, i)}><i className="active glyphicon glyphicon-ok-circle"></i></Button>)
    } else {
      return (<Button variant="link" onClick={() => this.changeAssetState(true, i)}><i className="inactive glyphicon glyphicon-remove-circle"></i></Button>)
    }
  }

  getAggregateAction(status) {
    switch(status) {
      case STATUS.SUBMITTED:
        return(<span>-</span>)
      case STATUS.ACCEPTED:
        return(<span>-</span>)
      case STATUS.REJECTED: 
        return (<Button variant="success" onClick={this.submitAggregate.bind(this)}>Submit Aggregates</Button>)
      case STATUS.OPEN: 
        //if (this.state.result.filter(submission => submission.status === STATUS.ACCEPTED).length === this.state.result.length) {
          return (<Button variant="success" onClick={this.submitAggregate.bind(this)}>Submit Aggregates</Button>);
        //} else {
        //  return(<span>-</span>)
        //}
      default:
        return(<span>-</span>)
    }
  }


  getAggregatedTotals() {
    publisherService.getAggregaedTotals().then((response) => {
      var aggregate = response[0];
      this.setState({
        aggregateStatus: aggregate.status,
        publisherComment: aggregate.publisherComment
      });
    }).catch((error) => {
      console.log(error);
    })
  }

  submitAggregate() {
    // console.log("summary data--",this.props.summaryData)
    console.log( this.state.aggLightAmount,this.state.aggMiddleAmount,this.state.aggHeavyAmount)

    if (this.state.result.filter(submission => submission.status === STATUS.ACCEPTED && submission.active === true).length === this.state.result.filter(submission => submission.active === true).length) {
    
      if ((this.props.summaryData.totalCount != this.props.summaryData.submittedCount) ||
        (this.state.aggLightAmount === 0 && this.state.aggMiddleAmount === 0 && this.state.aggHeavyAmount === 0)){
        alert("Submit aggregates not allowed untill all the operators submitted the totals.");
        return;
      }
      else{
        confirmAlert({
          title: 'Confirm to submit',
          message: 'The total aggregates are submitting to publisher, do you wish to continue?',
          buttons: [
            {
              label: 'Yes',
             onClick: () => {
              this.finalSubmitAggregates()
              }
            },
            {
              label: 'No',
              onClick: null
            }
          ],
          closeOnEscape: false,
         closeOnClickOutside: false
        });
       
      }
     
    } else {
      alert("Not all submission have been approved. Please approve all submission before submitting the aggregates.");
    }
  }

  finalSubmitAggregates() {
    this.props.loadingHandler(true);
    regulatorService.submitAggregate(this.state.aggLightAmount, this.state.aggMiddleAmount, this.state.aggHeavyAmount, this.state.aggLightCapacityAmount, this.state.aggMiddleCapacityAmount, this.state.aggHeavyCapacityAmount).then(response => {

      var data = this.state.result;

      this.setState({
        result: data,
        showConfirmation: false,
        confirmationTitle: 'Submitted Successfully',
        confirmationMessage: 'Aggregates Submitted Successfully',
        aggregateStatus: STATUS.SUBMITTED,
        alertAction: 'submitAggregates'
      });

      this.props.loadingHandler(false);
    }).catch(error => {
      alert("Unable to submit the Aggregates");
      this.props.loadingHandler(false);
    })
  }

  getSubmissionDate(date) {
    if (date === null || date === undefined) {
      return (<span>-</span>);
    }

    return (<Moment format="DD MMM YYYY HH:mm">{date}</Moment>)

  }
  getPublishDate(date) {
    if (date === null || date === undefined) {
      return (<span>-</span>);
    }

    return (<Moment format="DD MMM YYYY">{date}</Moment>)

  }

  toggleCapacity() {
    this.setState({
      showCapacityValues: !this.state.showCapacityValues
    });
  }

  setAggregateAmounts() {
    this.setState({
      aggLightAmount: this.state.result.reduce(function(accumulator, obj) { return accumulator + (obj.active === true ? obj.lightAmount : 0);}, 0),
      aggMiddleAmount: this.state.result.reduce(function(accumulator, obj) { return accumulator + (obj.active === true ? obj.middleAmount : 0);}, 0),
      aggHeavyAmount: this.state.result.reduce(function(accumulator, obj) { return accumulator + (obj.active === true ? obj.heavyAmount : 0);}, 0),
      aggLightCapacityAmount: this.state.result.reduce(function(accumulator, obj) { return accumulator + (obj.active === true ? obj.lightCapacityAmount : 0);}, 0),
      aggMiddleCapacityAmount: this.state.result.reduce(function(accumulator, obj) { return accumulator + (obj.active === true ? obj.middleCapacityAmount : 0);}, 0),
      aggHeavyCapacityAmount: this.state.result.reduce(function(accumulator, obj) { return accumulator + (obj.active === true ? obj.heavyCapacityAmount : 0);}, 0)
    });
  }

  render() {

    if (this.props.data == null || this.state.result == null) {
      return(null)
    }

    var title = null;
    if (this.props.title != null) {
       title = (<h2>{this.props.title}</h2>);
    }

    return (
        <div className="moduleContainer">

          {this.state.showConfirmation ? <AlertModal title={this.state.confirmationTitle} message={this.state.confirmationMessage} modalAction={this.modalAction.bind(this)}/> : null}
          {this.state.showReasonModal && this.state.selectedItemIndex != null ? <DeclineReasonModal declineAction={this.declineSubmissionWithReason.bind(this)} closeAction={this.closeModalAction.bind(this)} selectedItemIndex={this.state.selectedItemIndex}/> : null}
          { this.props.title !== null && this.state.showCapacityValues ? <h2>Capacity levels</h2> : title }
          <a className="capacity-toggle" onClick={this.toggleCapacity.bind(this)}>{this.state.showCapacityValues ? 'Show submissions' : 'Show capacities' }</a>
          <Table responsive>
            <thead>
              <tr>
                <th>Active</th>
                <th>Submitted Date</th>
                <th>Publish Date</th>
                <th>Operator</th>
                <th>Light</th>
                <th>Middle</th>
                <th>Heavy</th>
                {this.state.showCapacityValues ? null : <th>Status</th> }
                {this.state.showCapacityValues ? null : <th>Action</th> }
              </tr>
            </thead>
            <tbody>
              {this.state.result.map((item, i) =>
                <tr key={i}>
                  <td className="center-content">{this.getCommodityStatus(item.active, i)}</td>
                  <td>{this.getSubmissionDate(item.submissionDate)}</td>
                  <td>{this.getPublishDate(item.currentPeriod)}</td>
                  <td>
                    <b>{item.organization.name}</b><br />
                    {this.getSubmittedBy(item)}
                  </td>
                  <td>{this.state.showCapacityValues ? item.lightCapacityAmount : item.lightAmount}</td>
                  <td>{this.state.showCapacityValues ? item.middleCapacityAmount : item.middleAmount}</td>
                  <td>{this.state.showCapacityValues ? item.heavyCapacityAmount : item.heavyAmount}</td>
                  <td className="center-content"><span className={getStatusClass(item.status)} data-tip={getStatusText(item.status, item.regulatorComment)}></span></td>
                  {this.state.showCapacityValues ? null : <td>
                    {this.getAction(item.status, i)}
                  </td>}
                </tr>
              )}

              <tr>
                <td colSpan={4}><b>Aggregate Total</b></td>
                <td><b>{this.state.showCapacityValues ? this.state.aggLightCapacityAmount : this.state.aggLightAmount }</b></td>
                <td><b>{this.state.showCapacityValues ? this.state.aggMiddleCapacityAmount : this.state.aggMiddleAmount }</b></td>
                <td><b>{this.state.showCapacityValues ? this.state.aggHeavyCapacityAmount :  this.state.aggHeavyAmount }</b></td>
                <td className="center-content"><SubmissionStatusIcon status={this.state.aggregateStatus} isAggregates = {true} publisherComment={this.state.publisherComment}/></td>
                {this.state.showCapacityValues ? null :   <td>{this.getAggregateAction(this.state.aggregateStatus)}</td>}
              </tr>

            </tbody>
          </Table>    
          <a href="/submission/history" className="submission-history-link">View submission history</a>          
          <ReactTooltip />          
      </div>
    );
  }
}

export default SubmissionsDataTable;
