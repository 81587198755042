import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

// Images 
import ComputerImg from '../../gui/images/computer.png';
import EncryptImg from '../../gui/images/encrypting.png';
import SubmittingImg from '../../gui/images/submitting.png';
import ServersImg from '../../gui/images/servers.png';

import ComputerDoneImg from '../../gui/images/computer-done.png';
import ServersDoneImg from '../../gui/images/servers-done.png';
import EncryptDoneImg from '../../gui/images/encrypting-done.png';
import SubmittingDoneImg from '../../gui/images/submitting-done.png';

// CSS
import './DataSubmissionProgressView.css';

import { cryptoRandom } from '../../constants/index.js'

var interval, progressBarInterval; 

class DataSubmissionProgressView extends Component {

    constructor(props) {
        super(props) 

        this.state = {
            progressStage: 1, 
            progressText: 'Connecting to Server', 
            progress: 0, 
            chars: '!<>-_\\/[]{}—=+*^?#________'
        }
        this.progress = this.progress.bind(this);
        this.setText = this.setText.bind(this);
        this.update = this.update.bind(this);
        this.randomChar = this.randomChar.bind(this);

        this.startProgressAnimation = this.startProgressAnimation.bind(this);
        this.startProgressAnimation();
    }

    startProgressAnimation() {
        interval = setInterval(this.progress.bind(this), 1500);
        progressBarInterval = setInterval(this.updateProgressBar.bind(this), 200);
    }

    updateProgressBar() {

        if (this.state.progress >= 100) {
            clearInterval(progressBarInterval);
            return;
        }

        this.setState({
            progress: this.state.progress + 1
        });
    }

    progress() {
        switch (this.state.progressStage) {
            case 1:  // Connecting
                this.setState({
                    progressStage: 2, 
                    progressText: 'Submitting your submission', 
                    progress: 25
                });

                this.setText(this.state.progressText);

                break;
            case 2: // Encrypting 
                this.setState({
                    progressStage: 3, 
                    progressText: 'Sending to FEDCom', 
                    progress: 50
                });

                break;
            case 3: // Submitting 
                this.setState({
                    progressStage: 4, 
                    progressText: 'Submission is received by FEDCom', 
                    progress: 75
                });
                break;
            case 4: // Saving 
                this.setState({
                    progressStage: 5, 
                    progressText: 'Complete', 
                    progress: 100
                });                
                break; 
            case 5: 
                this.setState({
                    progressStage: 6, 
                    progressText: null, 
                    progress: 100
                });    
                clearInterval(interval);
                this.props.completeAction();
                break;
            default:
                break;
        }
    }

    /* Scramble effect */
    setText(newText) {
        const oldText = 'Encrypting your submission'
        const length = Math.max(oldText.length, newText.length)
        const promise = new Promise((resolve) => this.resolve = resolve)
        this.queue = []

        for (let i = 0; i < length; i++) {
            const from = oldText[i] || ''
            const to = newText[i] || ''
            const start = Math.floor(cryptoRandom() * 40)
            const end = start + Math.floor(cryptoRandom() * 40)
            this.queue.push({ from, to, start, end })
        }
        cancelAnimationFrame(this.frameRequest)
        this.frame = 0
        this.update()
        return promise
    }

    update() {
        let output = ''
        let complete = 0
        for (let i = 0, n = this.queue.length; i < n; i++) {
            let { from, to, start, end, char } = this.queue[i]
            if (this.frame >= end) {
                complete++
                output += to
            } else if (this.frame >= start) {
                if (!char || cryptoRandom() < 0.28) {
                    char = this.randomChar()
                    this.queue[i].char = char
                }
                output += char
            } else {
                output += from
            }
        }

        this.setState({
            progressText: output
        });
        
        if (complete === this.queue.length) {
            this.resolve()
        } else {
            this.frameRequest = requestAnimationFrame(this.update)
            this.frame++
        }
    }

    randomChar() {
        return this.state.chars[Math.floor(cryptoRandom() * this.state.chars.length)]
    }
    /* End scramble effect */

    render() {
        var progressBody;

        if (this.state.progressStage < 6) {
            progressBody = (
                <div className="progressContainer">
                    <h2>{this.state.progressText}</h2>
                    <div className="progressImages">
                        <img src={this.state.progressStage > 1 ? ComputerDoneImg : ComputerImg} alt=""/>
                        <img src={this.state.progressStage > 2 ? EncryptDoneImg : EncryptImg} alt=""/>
                        <img src={this.state.progressStage > 3 ? SubmittingDoneImg : SubmittingImg} alt=""/>
                        <img src={this.state.progressStage > 4 ? ServersDoneImg : ServersImg} alt=""/>
                    </div>
                     <ProgressBar now={this.state.progress} />
                </div>
            );
        } else {
            progressBody = (
                <div>
                    <h2>Totals submitted successfully</h2>
                    <p>FEDCom has been notified of your submission and will review your totals shortly.</p>
                    <br />
                    <Button variant="success" className="col-sm-offset-10" onClick={this.props.closePopup}>OK</Button>

                </div>
            );
        }

        return(
            <div id="data-submission-progress-view">
                { progressBody }
            </div>
        );
    }
}

export default DataSubmissionProgressView;

