import React, { Component } from 'react';
import {Form, Button} from 'react-bootstrap';
import { getAPIPath, USER_TYPE, headersWithMethod, fetchAPI } from '../../constants';

// Css 
import './ResetPasswordView.css';

const APIPATH = getAPIPath(USER_TYPE.RESET);

class ResetPasswordView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: null, 
            requestCode: true, 
            code: null, 
            newPwd: '', 
            confirmNewPwd: '',
            passwordValidation: true, 
            passwordValidationMessage: null,
            verificationPhoneNumber: null
        }

        this.handleReset = this.handleReset.bind(this);
        this.requestCode = this.requestCode.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    handleReset(e) {
        e.preventDefault();

        if (this.state.requestCode) {
            this.requestCode()
        } else {
            this.resetPassword();
        }
    }

    requestCode() {
        if (this.validateEmail()) {            
           fetchAPI(APIPATH + "/reset/" + this.state.email, headersWithMethod('GET'), this.props).then(response => response.json())
            .then(response => {
                if (response.userDetails) {  
                    this.setState({
                        requestCode: false
                    });  
                } else {
                    alert(response.message);
                    return;
                }               
            }).catch((error) =>{
                console.log(error);
            })
        }
    }

    resetPassword() {        
        if (this.validateNewPassword()) {
            fetchAPI(APIPATH + "/reset", headersWithMethod('POST',
            JSON.stringify({                    
                "code": this.state.code,
                "password": this.state.newPwd,
                "email": this.state.email
            }))).then(response => {                              
                if (response.status === 200) {  
                    alert("Password reset successfully. Please login to continue."); 
                    window.location.href = '/';
                } else {
                    alert('Password reset is failed, Please check the verification code and the password that you entered.');
                    return;
                }
            }).catch((error) =>{
                console.error("Reset Failed: " + error);
            })
        }
    }

    validateEmail() {
        if (this.state.email == null) {
            alert('Please enter an email address');
            return false; 
        }

        return true; 
    }

     validateNewPassword() {

        if (this.state.newPwd !== this.state.confirmNewPwd) {
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password and confirm password both do not match."
            });

            return false; 
        }

        if (this.state.newPwd.length < 8) {
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password must be at least 9 characters or more in length."
            });
            return false; 
        }

        // Check for numbers 
        var r = /\d+/;        
        if (this.state.newPwd.match(r) == null) {
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password must contain at least one number."
            });
            return false; 
        }

        // Check for lowercase 
        r = /[a-z]/;        
        if (this.state.newPwd.match(r) == null) {
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password must contain at least one lowercase letter."
            });
            return false; 
        }

        // Check for uppercase
        r = /[A-Z]/;        
        if (this.state.newPwd.match(r) == null) {
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password must contain at least one uppercase letter."
            });
            return false; 
        }

        // Check for special characters
        r = /\W/;  
        if(this.state.newPwd.match(r) == null){
            this.setState({
                passwordValidation: false, 
                passwordValidationMessage: "New password must contain at least one special character."
            });
            return false; 
        }

        return true; 

    }

     handleChange(column, value) {
        const newState = {};
        newState[column] = value;

        this.setState(newState);
    }

    cancel() {
        window.location.href = '/';
    }

    render() {
        const instructions = this.state.requestCode ? (
            <div className="titleContainer">
                <h2>Reset your password</h2>
                <p>Enter your email address into the field below and click the submit button.</p>                                                     
            </div>
        ) : (
            <div className="titleContainer">
                <h2>Create password</h2>
                <p>A verification code has been sent to the email address you've entered.</p>   
                {!this.state.passwordValidation && <p className="error">{this.state.passwordValidationMessage}</p>}                                                  
            </div>
        );

        const verificationFields = this.state.requestCode ? null : (
            <div>
                <Form.Group>
                    <Form.Control type="text" placeholder="Verification Code" onChange={e => this.handleChange('code', e.target.value)} value={this.state.code}/>
                    <Form.Control.Feedback />
                </Form.Group>    
                <Form.Group>
                    <Form.Control type="password" placeholder="New Password" autocomplete="off" onChange={e => this.handleChange('newPwd', e.target.value)} className="password"/>
                    <Form.Control.Feedback />
                </Form.Group>
                <Form.Group>
                    <Form.Control type="password" placeholder="Confirm New Password" autocomplete="off" onChange={e => this.handleChange('confirmNewPwd', e.target.value)} className="password"/>
                    <Form.Control.Feedback />
                </Form.Group>                            
            </div>            
        )

        return(
            <div id="forgotPassword-view">
                <div className="loginLogo"></div>
                <div className="forgotPasswordContainer">                                        
                    {instructions}                    
                    <div><br /></div>
                    <div className="formContainer">                        
                        <Form onSubmit={e => this.handleReset(e)}>
                            <div className="padding">
                                <Form.Group className="form-group">
                                    <Form.Control type="email" placeholder="Email address" onChange={e => this.handleChange('email', e.target.value)} className="username" value={this.state.email}/>
                                    <Form.Control.Feedback />
                                </Form.Group>                                
                                {verificationFields}
                            </div>
                            <Form.Group className="form-group">
                                <Button type="submit" className="forgotPassword-button">Submit</Button>
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Button type="button" className="forgotPassword-link btn-link" onClick={this.cancel.bind(this)}>Cancel</Button>
                            </Form.Group>
                        </Form>
                    </div>                    
                </div>                      
            </div>
        )
    }

}

export default ResetPasswordView;