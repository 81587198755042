import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert';
import ReactTooltip from 'react-tooltip'
// Modules 
import AlertModal from '../../Modules/AlertModal';
import DeclineReasonModal from '../../Modules/DeclineReasonModal';
import SubmissionStatusIcon from '../../Modules/SubmissionStatusIcon';

// Services 
import { publisherService } from '../../Services/publisherService.js';
import { globalService } from '../../Services/globalService.js';

// CSS
import 'react-confirm-alert/src/react-confirm-alert.css';


class PlattsSubmissionDataTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showReasonModal: false, 
            selectedItemIndex: null, 
            showConfirmation: false, 
            confirmationTitle: null,
            confirmationMessage: null, 
            data: null
        }
    }

    componentWillReceiveProps() {    
        this.setState({
            data: this.props.data
        });
    }

    getStatus(status) {
        var statusClasses = "status-indicator";
        switch (status) {
        case "SUBMITTED": 
            statusClasses += " neutral";
            break;
        case "ACCEPTED": 
        case "PUBLISH":
            statusClasses += " approved";
            break; 
        case "REJECTED": 
            statusClasses += " declined";
            break;
        default: 
            statusClasses += " neutral";
        }        

        return statusClasses;
    };  

    approveSubmission(i) {
        confirmAlert({
            title: 'Confirm to approve',
            message: 'This action can not be undone, Are you sure you want to approve the totals?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.loadingHandler(true);  
                        publisherService.approveSubmission().then((response) => {            
                
                            var data = this.state.data;
                            data[i].status = "ACCEPTED";
                
                            this.props.loadingHandler(false);  
                
                        });  
                    }
                },
                {
                    label: 'No',
                    onClick: null
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false
        });      
    }

    declineSubmission(i) {
        this.setState({
            showReasonModal: true, 
            selectedItemIndex: i 
        });
    }
    declineSubmissionWithReason(i, reason) {
        publisherService.declineSubmission(reason).then((response) => {            

            var data = this.state.data;
            data[i].status = "REJECTED";            
    
            this.setState({
                data: data,
                showConfirmation: true,  
                confirmationTitle: 'Declined Successfully',
                confirmationMessage: 'The submission has been declined successfully.', 
                selectedItemIndex: null 
            });

            this.props.loadingHandler(false);  
            this.closeModalAction();
        });  
    }

    publishTotals() {
        if (this.props.data && this.props.data.length > 0) {
            confirmAlert({
                title: 'Confirm to submit',
                message: 'The total aggregates will be published to global, do you wish to continue?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                           this.finalPublishTotals()
                        }
                    },
                    {
                        label: 'No',
                        onClick: null
                    }
                ],
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        } else {
            alert("Aggregates cannot be submitted.");
        }
    }

    finalPublishTotals() {
        this.props.loadingHandler(true);

        const submissionAggregate = this.props.data[0];
        const submissions = this.props.data[0].submissions;
        const lightCommodity = submissions.filter(sm => sm.commodityType === 'light');
        const middleCommodity = submissions.filter(sm => sm.commodityType === 'middle');
        const heavyCommodity = submissions.filter(sm => sm.commodityType === 'heavy');
        const lightAmount = lightCommodity[0].amount;
        const middleAmount = middleCommodity[0].amount;
        const heavyAmount = heavyCommodity[0].amount;
        const lightCapacityAmount = lightCommodity[0].capacity;
        const middleCapacityAmount = middleCommodity[0].capacity;
        const heavyCapacityAmount = heavyCommodity[0].capacity;

        globalService.publishAggregateTotals(submissionAggregate.currentPeriod, submissionAggregate.ipAddress, lightAmount, middleAmount, heavyAmount, lightCapacityAmount, middleCapacityAmount, heavyCapacityAmount).then((response) => {
            this.props.loadingHandler(false);
            alert("Aggregates published successfully");
            window.location.href = window.location.href;
        });
    }

     getAction(status, i) {         
        switch(status) {
        case "SUBMITTED": 
            return (<div><Button variant="success" onClick={() => this.approveSubmission(i)}>Approve</Button> <Button variant="danger" onClick={() => this.declineSubmission(i)}>Decline</Button></div>)        
        case "ACCEPTED":
        case "PUBLISH":
            return (<Button variant="success" onClick={this.publishTotals.bind(this)}>Publish Aggregates</Button>)
        default: 
            return(<span>-</span>)
        }
    }
    closeModalAction() {
        this.setState({
            showReasonModal: false 
        })
    }
    modalAction() {
        this.setState({
            showConfirmation: false
        });
        window.location.href = window.location.href;
    }

    render() {

        if (this.props.data == null || this.props.data.length <= 0) {
           return null;
        }

        var title = null; 
        if (this.props.title != null) {
            title = (<h2>{this.props.title}</h2>);
        }

        return(            
            <div className="moduleContainer">
                    
                { this.props.title == null ? null : title }    

                {this.state.showConfirmation ? <AlertModal title={this.state.confirmationTitle} message={this.state.confirmationMessage} modalAction={this.modalAction.bind(this)}/> : null}      
                {this.state.showReasonModal && this.state.selectedItemIndex != null ? <DeclineReasonModal declineAction={this.declineSubmissionWithReason.bind(this)} closeAction={this.closeModalAction.bind(this)} selectedItemIndex={this.state.selectedItemIndex}/> : null}
                
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Submitted Date</th>
                            <th>Publish Date</th>
                            <th>Submitted by</th>
                            <th>Light</th>
                            <th>Middle</th>
                            <th>Heavy</th>
                            {this.props.showHistory ? null : (<th>Status</th>)}
                            {this.props.showHistory ? null : (<th>Actions</th>)}
                        </tr>
                    </thead>
                    <tbody>
                    {this.props.data && this.props.data.length > 0 &&
                    this.props.data[0] !== undefined && this.props.data.map((item, i) =>
                        <tr key={i}>
                           {item.submittedDate !== null ? <td><Moment format="DD MMM YYYY HH:mm">{item.submittedDate}</Moment></td> : <td></td>}
                            <td><Moment format="DD MMM YYYY">{item.currentPeriod}</Moment></td>
                            <td>
                                <b>FEDCom</b><br />
                                 ({item.ipAddress})
                            </td>
                            <td>{item.submissions && item.submissions.length > 0 ? item.submissions[0].amount :  null}</td>
                            <td>{item.submissions && item.submissions.length > 0 ? item.submissions[1].amount :  null}</td>
                            <td>{item.submissions && item.submissions.length > 0 ? item.submissions[2].amount :  null}</td>
                            {this.props.showHistory ? null : (<td className="center-content"><SubmissionStatusIcon status={item.status} isAggregates = {true} publisherComment={item.publisherComment}/></td>)}
                            {this.props.showHistory ? null : (<td>{this.getAction(item.status, i)}</td>)}
                        </tr>
                    )}              
                    </tbody>
                </Table>    
                <ReactTooltip />      
            </div>
        );        
    }

}

export default PlattsSubmissionDataTable;