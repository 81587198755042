import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Views 
import PlattsPublishedTotalsView from '../PlattsPublishedTotalsView';

// Modules 
import PlattsSubmissionsDataTable from '../../Modules/PlattsSubmissionsDataTable';

// Services 
import { publisherService } from '../../Services/publisherService.js';

class PlattsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            historicData: null, 
            currentData: null, 
            loading: true
        }

        this.setLoading = this.setLoading.bind(this);
        this.getSubmissionsData = this.getSubmissionsData.bind(this);
        this.getCurrentAggregatedTotals = this.getCurrentAggregatedTotals.bind(this);
        this.getSubmissionsData()
        this.getCurrentAggregatedTotals();

    }

    setLoading(isLoading) {
        this.props.loadingHandler(isLoading);
    }

    getCurrentAggregatedTotals() {
        this.setLoading(true);
        publisherService.getAggregaedTotals().then((response) => {            
            this.setState({
                currentData: response
            });
            this.setLoading(false);
        })
    }

    getSubmissionsData() {
        this.setLoading(true);
        publisherService.getSumbissionTableData().then((dataResponse) => {
            this.setState({
                historicData: dataResponse
            });
            this.setLoading(false);
        });
    }

    render() {
        return(
            <div>
                <Container>                
                    <Row>
                        <Col>
                            <PlattsPublishedTotalsView horizontal/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PlattsSubmissionsDataTable data={this.state.currentData} title="Current Submission" loadingHandler={this.setLoading}/>
                        </Col>
                    </Row>                    
                    <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PlattsSubmissionsDataTable data={this.state.historicData} title="Previous submissions" showHistory={true}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="spacer">&nbsp;</div>
                        </Col>
                    </Row>
                </Container>                        
            </div>    
        ); 
    }
}

export default PlattsView; 