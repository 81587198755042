import React, { Component } from 'react';

// Modules 
import LevelsPieChart from '../../Modules/LevelsPieChart';

import './SummaryDataView.css';

class SummaryDataView extends Component {   
    constructor(props) {
        super(props);

        this.state = {
            data: null
        }     
    }

    render() {

        if (this.props.data == null) {
            return null;     
        }
        
        return(
            <div className="moduleContainer">
                <h2>Your current levels</h2>
            
                <div className="pieChartContainer">
                    <LevelsPieChart level={this.props.data.commodities.light.amount} volume={this.props.data.commodities.light.capacity} commodityType="light" />
                </div>
                <div className="pieChartContainer">
                    <LevelsPieChart level={this.props.data.commodities.middle.amount} volume={this.props.data.commodities.middle.capacity} commodityType="middle" />
                </div>
                <div className="pieChartContainer">
                    <LevelsPieChart level={this.props.data.commodities.heavy.amount} volume={this.props.data.commodities.heavy.capacity} commodityType="heavy" />
                </div>
            </div> 
        );
    }
}
export default SummaryDataView;