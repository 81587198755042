import React, {Component} from 'react'; 
import BeatLoader from "react-spinners/BeatLoader";
// CSS 
import './Loading.css';

class Loading extends Component {
    constructor(props) {
        super(props);

        this.state ={
            visible: false
        }
    }

    componentWillReceiveProps() {
        this.setState({
            visible: this.props.visible 
        })
    }

    render() {
        if (!this.props.visible) {
            return null; 
        }

        return(
            <div className="loading-container" >
            <div>
            <BeatLoader size={16} color="#FFFFFF" />
            </div>
            </div>
        )
    }

}

export default Loading; 