import React, {Component} from 'react';
import { Container, Row, Col, Modal, Button,Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { getAPIPath, USER_TYPE, headersWithMethod, fetchAPI } from '../../constants';

// Const
import {regulatorData, totalsData} from '../../constants';

class ModulePreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            organisationId: null,             
            organisationRole: null,
            adminFirstName: null, 
            adminLastName: null, 
            adminEmailAddress: null, 
            adminMobileNumber: null
        }

        this.validateData = this.validateData.bind(this);
        this.addOrganisation = this.addOrganisation.bind(this);
    }

    showValidation(fieldValidated) {
        
        if (fieldValidated == null) {
            return null;
        }

        if (!fieldValidated) {
            return 'error';
        }

        return null;
    }

    handleChange(column, value) {
        const newState = {};
        newState[column] = value;
        this.setState(newState);
    }


    handleSubmit() {
        if (this.validateData()) {
            this.addOrganisation();
        }
    }

    verifyEmailAddress() {
        if (this.state.adminEmailAddress == null) {
            alert('Please enter a valid email address');
            return;
        }

        fetchAPI('http://localhost:4000/verify/' + this.state.adminEmailAddress, headersWithMethod('GET')).then((response) => {            
            if (response.status === 200) {
                alert("Email Verified Successfully");         
                this.resetForm();                                
            } else {
                response.json().then((json) => {
                    alert("Unable to parse the json data");
                });                
                return;
            }
        }).catch((error) =>{
            console.error('ERROR:', error);
        })

    }

    validateData() {
        if (this.state.organisationId == null ||             
            this.state.organisationRole == null || 
            this.state.adminFirstName == null || 
            this.state.adminLastName == null || 
            this.state.adminEmailAddress == null || 
            this.state.adminMobileNumber == null) {
            alert("Please complete all details!");
            return false; 
        }

        return true; 
    }

    addOrganisation(e) {

        fetchAPI('http://localhost:4000/add', headersWithMethod('POST',
            JSON.stringify({
                "organisationId": this.state.organisationId,                
                "organisationRole": this.state.organisationRole,
                "adminFirstName": this.state.adminFirstName,
                "adminLastName": this.state.adminLastName,
                "adminEmailAddress": this.state.adminEmailAddress,
                "adminMobileNumber": this.state.adminMobileNumber,
                "admin": 1
        }))).then((response) => {            
            if (response.status === 201) {
                alert("Participant Added Successfully");         
                this.resetForm();                
                this.getParticipants();
            } else {
                response.json().then((json) => {
                    alert("Unable to parse the json data");
                });                
                return;
            }
        }).catch((error) =>{
            console.error('ERROR:', error);
        })
    }

    render() {
        // Temp data       
        return(
            <div id="module-preview">                
                <Form>
                <Container>                                        
                    <Row>
                        <Col>
                            <div className="container">
                                <div className="moduleContainer">
                                    <h2>Organisation details</h2>
                                    <div>
                                        <Form.Group>                                    
                                            <Col componentClass={FormLabel} sm={2}>
                                                Organisation Id
                                            </Col>
                                            <Col sm={10}>                                
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Organisation Id" 
                                                    onChange={e => this.handleChange('organisationId', e.target.value)}/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group>
                                            <Col componentClass={FormLabel} sm={2}>
                                                Role                                    
                                            </Col>
                                            <Col sm={10}>                                                
                                                <Form.Control componentClass="select" placeholder="select" onChange={e => this.handleChange('organisationRole', e.target.value)}>
                                                    <option value="select">Select a role</option>
                                                    <option value="operator">Operator</option>
                                                    <option value="regulator">Regulator</option>
                                                    <option value="publisher">Publisher</option>
                                                </Form.Control>                                                
                                            </Col>
                                        </Form.Group>                                        
                                    </div>
                                </div>
                            </div>                                
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className="container">
                                <div className="moduleContainer">
                                    <h2>Administrator Details</h2>
                                    <div>
                                        <Form.Group>
                                            <Col sm={2}>                    
                                                <Form.Label>First name</Form.Label>
                                            </Col>
                                            <Col sm={10}>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Enter a first name" 
                                                    onChange={e => this.handleChange('adminFirstName', e.target.value)}
                                                    />
                                                <Form.Control.Feedback />
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group>
                                            <Col componentClass={FormLabel} sm={2}>                    
                                                <Form.Label>Last name</Form.Label>
                                            </Col>
                                            <Col sm={10}>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Enter a last name" 
                                                    onChange={e => this.handleChange('adminLastName', e.target.value)}
                                                    />
                                                <Form.Control.Feedback />
                                            </Col>
                                        </Form.Group>              
                                        <Form.Group>
                                            <Col componentClass={FormLabel} sm={2}>                    
                                                <Form.Label>Email Address</Form.Label>
                                            </Col>
                                            <Col sm={10}>
                                                <Form.Control 
                                                    type="email" 
                                                    placeholder="Enter an email address" 
                                                    onChange={e => this.handleChange('adminEmailAddress', e.target.value)}
                                                    />
                                                <Form.Control.Feedback />
                                            </Col>
                                        </Form.Group>         
                                        <Form.Group>
                                            <Col componentClass={FormLabel} sm={2}>                    
                                                <Form.Label>Mobile number</Form.Label>
                                            </Col>
                                            <Col sm={10}>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Enter a mobile number" 
                                                    onChange={e => this.handleChange('adminMobileNumber', e.target.value)} 
                                                    />
                                                <Form.Control.Feedback />
                                            </Col>
                                        </Form.Group>                                           
                                    </div>
                                </div>
                            </div>    
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className="container">
                                <div className="moduleContainer">
                                    <Form.Group>          
                                        <Col sm={5}>
                                        </Col>
                                        <Col sm={6}>                                     
                                            <Button type="button" onClick={this.handleSubmit.bind(this)}>Add Organsation</Button> &nbsp;      
                                            <Button type="button" onClick={this.verifyEmailAddress.bind(this)} bsStyle="warning">Verify Email Address</Button>                                            
                                        </Col>
                                    </Form.Group>                 
                                </div>
                            </div>                                
                        </Col>
                    </Row>
                </Container>   
                </Form>             
                <br /><br />
            </div>
            
        );        
    }
}

export default ModulePreview; 