import { getAPIPath, USER_TYPE, headersWithMethod, fetchAPI} from '../constants';
const moment = require('moment');
const APIPATH = getAPIPath(USER_TYPE.REGULATOR);
const PUBLISHER_APIPATH = getAPIPath(USER_TYPE.PUBLISHER);
import {publicIpv4} from 'public-ip';

export const regulatorService = {
    /*
        Get historical agregate submissions for operator
    */
    getHistoricalAggregateSubmissions: function() {
        return new Promise((resolve, reject) => {            
            fetchAPI(PUBLISHER_APIPATH +'/getHistoricalAggregateSubmissions', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {                                                        
                resolve(json.submissions);
            }).catch((error) => {
                console.error('Error getting regulator aggregate submissions: ' + error);
            });
        });
    },
    /*
        Get historical submissions for operator
    */
    getHistoricalSubmissions: function() {
        return new Promise((resolve, reject) => {            
            fetchAPI(APIPATH +'/showHistoricSubmissions', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {
                let orgHistorySubmissions = [];
                if(json.submissions){
                    for(var i=0; i<json.submissions.length;i++){
                        var submission = {'currentPeriod':'','userName':'','ipAddress':'','orgName':'',
                        'lightAmount':0,'middleAmount':0,'heavyAmount':0,'submittedDate':''};
                        const historySubmission = json.submissions[i].submission;
                        if(typeof historySubmission === 'string') {
                            continue;
                        }
                        submission.currentPeriod = historySubmission.currentPeriod;
                        submission.userName = historySubmission.userName;
                        submission.ipAddress = historySubmission.ipAddress;
                        submission.orgName = historySubmission.orgName;
                        const lightAmount = historySubmission.submissions ? historySubmission.submissions.filter(sm => sm.commodityType === 'light') : [];
                        const middleAmount = historySubmission.submissions ? historySubmission.submissions.filter(sm => sm.commodityType === 'middle') : [];
                        const heavyAmount = historySubmission.submissions ? historySubmission.submissions.filter(sm => sm.commodityType === 'heavy') : [];
                        submission.lightAmount = lightAmount.length > 0 ? lightAmount[0].amount : 0;
                        submission.middleAmount = middleAmount.length > 0 ? middleAmount[0].amount : 0;
                        submission.heavyAmount = heavyAmount.length > 0 ? heavyAmount[0].amount : 0;
                        submission.submittedDate = historySubmission.submittedDate;
                        orgHistorySubmissions.push(submission);
                    }
                }
                resolve(orgHistorySubmissions);      
            }).catch(error => {
                reject(error);
            });
        });
    },
    /*  
        Get historic aggregated data for bar chart
    */
    getHistoricAggregateTotals: function(commodityId) {
        return new Promise((resolve, reject) => {            
            fetchAPI(PUBLISHER_APIPATH +'/getHistoricalAggregateSubmissions', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {
                let aggregateTotalsForChart = [];
                if(json.submissions){
                    for(var i=0; i<json.submissions.length; i++){
                        var submissionRec = {'name':'', 'light':'', 'middle': '', 'heavy': ''};
                        submissionRec.name = moment(json.submissions[i].currentPeriod).format('DD MMM YY');;
                        const lightAmount = json.submissions[i].submissions ? json.submissions[i].submissions.filter(sm => sm.commodityType === 'light') : [];
                        const middleAmount = json.submissions[i].submissions ? json.submissions[i].submissions.filter(sm => sm.commodityType === 'middle') : [];
                        const heavyAmount = json.submissions[i].submissions ? json.submissions[i].submissions.filter(sm => sm.commodityType === 'heavy') : [];
                        submissionRec.light = lightAmount.length > 0 ? lightAmount[0].amount : 0;
                        submissionRec.middle = middleAmount.length > 0 ? middleAmount[0].amount : 0;
                        submissionRec.heavy = heavyAmount.length > 0 ? heavyAmount[0].amount : 0;
                        aggregateTotalsForChart.push(submissionRec);
                    }
                }
                const sortedTotalsForChart = aggregateTotalsForChart.length > 0 ? aggregateTotalsForChart.sort((a,b) => Date.parse(a.name) - Date.parse(b.name)) : [];
                resolve(sortedTotalsForChart);      
            });
        });
    },
    /* 
        Get all operator submissions for displaying in a table
    */
    getSumbissionTableData: function() {
        var orgSubmissions = [];
        return new Promise((resolve, reject) => {            
            fetchAPI(APIPATH +'/getOrganisationTotals', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {    
            if(json.organisationTotals && json.organisationTotals.submissions){
                const latestOrgSubmissions = json.organisationTotals.submissions;
                for(var i=0; i< latestOrgSubmissions.length; i++) {
                        var orgRecord = latestOrgSubmissions[i].submission;
                        if(typeof orgRecord === 'string') {
                            continue;
                        }
                        var submissionRec = {
                            'active': '', 'currentPeriod': '', 'status': '', 'heavyAmount': 0, 'heavyCapacityAmount': 0,
                            'id': '', 'lightAmount': 0, 'lightCapacityAmount': 0, 'middleAmount': 0,
                            'middleCapacityAmount': 0, 'regulatorComment': '',
                            'organization': {
                                'id': '', 'name': '',
                                'submittedBy': {
                                    'ipAddress': '', 'userId': '', 'userName': ''
                                }
                            },
                            'status': '',
                            'submittedDate': ''
                        };
                        const operatorId = orgRecord.org;
                        submissionRec.active = orgRecord.active;
                        submissionRec.currentPeriod = orgRecord.currentPeriod;
                        submissionRec.status = orgRecord.status;
                        submissionRec.submissionDate = orgRecord.submittedDate;
                        const lightCommodity = orgRecord.submissions ? 
                                orgRecord.submissions.filter(sm => sm.commodityType === 'light') : [];
                        const middleCommodity = orgRecord.submissions ? 
                                orgRecord.submissions.filter(sm => sm.commodityType === 'middle') : [];
                        const heavyCommodity = orgRecord.submissions ? 
                                orgRecord.submissions.filter(sm => sm.commodityType === 'heavy') : [];
                        submissionRec.regulatorComment = orgRecord.regulatorComment;
                        submissionRec.lightAmount = lightCommodity.length > 0 ? Number(lightCommodity[0].amount) : 0;
                        submissionRec.middleAmount = middleCommodity.length > 0 ? Number(middleCommodity[0].amount) : 0;
                        submissionRec.heavyAmount = heavyCommodity.length > 0 ? Number(heavyCommodity[0].amount) : 0;
                        submissionRec.lightCapacityAmount = lightCommodity.length > 0 ? Number(lightCommodity[0].capacity) : 0;
                        submissionRec.middleCapacityAmount = middleCommodity.length > 0 ? Number(middleCommodity[0].capacity) : 0;
                        submissionRec.heavyCapacityAmount = heavyCommodity.length > 0 ? Number(heavyCommodity[0].capacity) : 0;
                        submissionRec.id = operatorId;
                        submissionRec.organization.id = operatorId;
                        submissionRec.organization.name = orgRecord.orgName
                        submissionRec.organization.submittedBy.ipAddress = orgRecord.ipAddress;
                        submissionRec.organization.submittedBy.userId = orgRecord.userId;
                        submissionRec.organization.submittedBy.userName = orgRecord.userName;
        
                        orgSubmissions.push(submissionRec);
                }
            }
            resolve(orgSubmissions);      
        });
    });
}, 
    /*  
        Get current aggregates for specific period 
    */
    getCurrentAggregateForPeriod: function() {
        return new Promise((resolve, reject) => {
          fetchAPI(PUBLISHER_APIPATH + '/getLastApprovedAggregateTotals', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => { 
                if(json.submissions){
                    var commodityLevels = {
                        'commodities': {
                            'light': {'amount':0, 'capacity': 0},
                            'middle': {'amount':0, 'capacity': 0},
                            'heavy': {'amount':0, 'capacity': 0}
                        }
                    };
                    for(var i=0;i<json.submissions.length;i++){
                        let submissions = json.submissions[i].submissions;
                        const lightCommodity = submissions.filter(sm => sm.commodityType === 'light');
                        const middleCommodity = submissions.filter(sm => sm.commodityType === 'middle');
                        const heavyCommodity = submissions.filter(sm => sm.commodityType === 'heavy');

                        commodityLevels.commodities.light.amount = lightCommodity[0].amount;
                        commodityLevels.commodities.light.capacity = lightCommodity[0].capacity;

                        commodityLevels.commodities.middle.amount = middleCommodity[0].amount;
                        commodityLevels.commodities.middle.capacity = middleCommodity[0].capacity;

                        commodityLevels.commodities.heavy.amount = heavyCommodity[0].amount;
                        commodityLevels.commodities.heavy.capacity = heavyCommodity[0].capacity;
                    }
                    resolve(commodityLevels);
                } 
            }).catch((error) => {
                console.error('Error getting submission details: ' + error);
            });
        });
    }, 
    /*  
        Get current submission status summary for all operators for the active period 
    */
    getOperatorSubmissionSummary: function() {
        return new Promise((resolve, reject) => {
            
            fetchAPI(APIPATH + '/getOperatorSubmissionSummary', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {                                        
                resolve(json.submissions);  
            }).catch((error) => {
                console.error('Error getting submission details: ' + error);
            });
        });
    }, 
    /* 
        Get details of all submissions for the current period for the regulator 
    */
    getSubmissionDetails: function() {

        return new Promise((resolve, reject) => {

             fetchAPI(APIPATH + '/getsubmissiondetails', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => { 
                var submissionDetails = [];
                for (var i =0; i < json.length; i++) {
                    submissionDetails.push(json[i]);
                }

                resolve(submissionDetails);    
            }).catch((error) => {
                console.error('Error getting submission details: ' + error);
            });
        })

    }, 
    approveSubmission: function(organizationId) {
        if (!organizationId) {
            console.error("No organization ID supplied, method aborted");
            return;
        }

        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/updateSubmitterStatus', headersWithMethod('POST', JSON.stringify({
                "organizationId": organizationId,
                "approved": true,
                "comment": ""
            })))
            .then(response => response.json())
            .then(json => {
                resolve(json);
            }).catch((error) => {
                console.error('Error approving submission: ' + error);
            })
        });
    },
    revokeSubmission: function(organizationId) {
        if (!organizationId) {
            console.error("No organization ID supplied, method aborted");
            return;
        }
        
        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/updateSubmitterStatus', headersWithMethod('POST', JSON.stringify({
                "organizationId": organizationId,
                "approved": false,
                "comment": "Revoked submission by regulator."
            })))
            .then(response => response.json())
            .then(json => {
                resolve(json);
            }).catch((error) => {
                console.error('Error approving submission: ' + error);
            })
        });
    }, 
    declineSubmission: function(organizationId, reason) {

        if (!organizationId) {
            console.error("No organization ID supplied, method aborted");
            return;
        }

        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/updateSubmitterStatus', headersWithMethod('POST', JSON.stringify({
                "organizationId": organizationId,
                "approved": false, 
                "comment": reason
            })))
            .then(response => response.json())  
            .then(json => {
                resolve(json);
            }).catch((error) => {
                console.error('Error declining submission: ' + error);
            })
        });
    }, 
    submitAggregate: async function(lightValue, middleValue, heavyValue, lightCapacityValue, middleCapacityValue, heavyCapacityValue) {
        const ipAddress =  await publicIpv4();
         return new Promise((resolve, reject) => {
            fetchAPI(PUBLISHER_APIPATH + '/submitAggregateTotals', headersWithMethod('POST', JSON.stringify({
                "ipAddress": ipAddress,
                "userName": "",
                "submissions":[
                    {"commodityType": "light", "amount": lightValue, "id":"LIGHT001", "capacity": lightCapacityValue },
                    {"commodityType": "middle", "amount": middleValue, "id":"MIDDLE001", "capacity": middleCapacityValue },
                    {"commodityType": "heavy", "amount": heavyValue, "id":"HEAVY001", "capacity": heavyCapacityValue }                    
                ]
            })))
            .then(response => response.json())
            .then(json => {
                resolve(json);
            }).catch((error) => {
                reject(error);
            })
        });
    }, 
    changeAssetState: function(organisationId, active) {        
        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/updateSubmissionState', headersWithMethod('POST', JSON.stringify({
                    "active": active,
                    "organizationId": organisationId
            }))).then(() => {
                resolve();
            }).catch((error) => {
                reject(error);
            })
        });
    }
};