import { getAPIPath, USER_TYPE, headersWithMethod, fetchAPI } from '../constants';

const APIPATH = getAPIPath(USER_TYPE.OPERATOR);

const moment = require('moment');

export const operatorService = {
    /* Operator
     Historical individual submissions
    */
    getTotalsData: function(commodityId) {
        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/getOrganisationHistoricalTotals', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {      
                                
                var submissionDetails = [];
                for (var i =0; i < json.historicalTotals.length; i++) {
                    var submission = {'name': '','light': 0,'middle': 0,'heavy': 0, 'publishId': 0 };  
                if(json.historicalTotals[i].submissions && 
                   json.historicalTotals[i].submissions.length > 0){
                            let submissions = json.historicalTotals[i].submissions;
                            const lightCommodity = submissions.filter(sm => sm.commodityType === 'light');
                            const middleCommodity = submissions.filter(sm => sm.commodityType === 'middle');
                            const heavyCommodity = submissions.filter(sm => sm.commodityType === 'heavy');
                            submission.name = moment(json.historicalTotals[i].currentPeriod).format('DD MMM YY');
                            submission.light = lightCommodity.length > 0 ? lightCommodity[0].amount : null;
                            submission.middle = middleCommodity.length > 0 ? middleCommodity[0].amount : null;
                            submission.heavy = heavyCommodity.length > 0 ? heavyCommodity[0].amount : null;
                            submission.publishId = json.historicalTotals[i].PUBLISH_ID;
                    }
                   submissionDetails.push(submission);
                }
                var sortedSubmissions = submissionDetails.length > 0 ?
                                        submissionDetails.sort((a,b) => a.publishId - b.publishId) : [];
                resolve(sortedSubmissions);  
            }).catch((error) => {
                console.error('Error getting operator totals data: ' + error);
            });
        });
    },    
    /* Operator [current, PrePub]
     Get submissions for operator (probably best not to pass Id and rather use authenticated user for security) 
        @rowCount: Paging??? I think for first version we can ommit this
    */
    getOperatorSumbissions: function() {
         return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/getOrganisationHistoricalTotals', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {                                         
                var submissionDetails = [];
                for (var i =0; i < json.historicalTotals.length; i++) {
                    var submission = {'currentPeriod': '','lightAmount': 0,
                    'middleAmount': 0,'heavyAmount': 0, 'status': '', 'submittedDate': '',
                    'submittedBy': {'ipAddress':'', 'userId':'', 'userName':''}, 'timestamp': ''}; 
                    if(json.historicalTotals[i].submissions.length > 0){
                           let submissions = json.historicalTotals[i].submissions;
                           submission.currentPeriod = json.historicalTotals[i].currentPeriod;
                           submission.submittedDate = json.historicalTotals[i].submittedDate;
                           const lightCommodity = submissions.filter(sm => sm.commodityType === 'light');
                           const middleCommodity = submissions.filter(sm => sm.commodityType === 'middle');
                           const heavyCommodity = submissions.filter(sm => sm.commodityType === 'heavy');
                           submission.lightAmount = lightCommodity.length > 0 ? lightCommodity[0].amount : null;
                           submission.middleAmount = middleCommodity.length > 0 ? middleCommodity[0].amount : null;
                           submission.heavyAmount = heavyCommodity.length > 0 ? heavyCommodity[0].amount : null;
                           submission.lightCapacity = lightCommodity.length > 0 ? lightCommodity[0].capacity : 0;
                           submission.middleCapacity = middleCommodity.length > 0 ? middleCommodity[0].capacity : 0;
                           submission.heavyCapacity = heavyCommodity.length > 0 ? heavyCommodity[0].capacity : 0;
                           submission.status = json.historicalTotals[i].status;
                           submission.submittedBy.ipAddress = json.historicalTotals[i].ipAddress;
                           submission.submittedBy.userId = json.historicalTotals[i].userId;
                           submission.submittedBy.userName = json.historicalTotals[i].userName;
                           submissionDetails.push(submission);
                        }
                    }
                    resolve(submissionDetails); 
            }).catch((error) => {
                console.error('Error getting operator submissions: ' + error);
            });
        });        
    },     
    getOperatorPreviousSubmission: function() {
        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/getPreviousSubmission', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {                 
                resolve(json.previousSubmission);  
            }).catch((error) => {
                console.error('Error getting previous submission: ' + error);
            });
        });
    }, 
    submitCommodityAmount: function(userId, lightValue, middleValue, heavyValue) {
        
        if (lightValue === undefined || middleValue === undefined || heavyValue === undefined) {
            console.warn("No values supplied!");
            return; 
        }

        fetchAPI(APIPATH + '/submitOrganisationTotals', headersWithMethod('POST',JSON.stringify({
                "userId": userId,                                        
                "submissions":[
                    {"commodityType": "light", "amount": lightValue == null ?  0 : lightValue},
                    {"commodityType": "middle", "amount": middleValue == null ?  0 : middleValue},
                    {"commodityType": "heavy", "amount": heavyValue == null ?  0 : heavyValue}
                ]
            }))
        ).then((response) => {
            console.log(response);
        }).catch((error) =>{
            console.error('ERROR:', error);
        })
    }, 
    getLatestTotals: function() {
        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/getCurrentSubmission', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {                 
                resolve(json.submissionDetails);  
            }).catch((error) => {
                console.error('Error getting latest totals: ' + error);
            });
        });
    }, 
    canSubmitTotals: function() {
        return new Promise((resolve, reject) => {  
            fetchAPI(APIPATH + '/CanSubmitTotals', headersWithMethod('GET'))          
            .then(response => response.json())
            .then(json => {                 
                resolve(json.submissionStatus);  
            }).catch((error) => {
                console.error('Error getting latest totals: ' + error);
            });
        });
    }, 
    getAssets: function() {
       return new Promise((resolve, reject) => {        
            fetchAPI(APIPATH + '/getCurrentSubmission', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {   
                let assets = [];
                if(json.submissionDetails.currentPeriod){
                    var currentAsset = {
                        'submittedDate':json.submissionDetails.submittedBy.Date,
                        'currentPeriod':json.submissionDetails.currentPeriod,
                        'submittedBy':json.submissionDetails.submittedBy,
                        'lightAmount': parseInt(json.submissionDetails.light.amount),
                        'middleAmount': parseInt(json.submissionDetails.middle.amount),
                        'heavyAmount': parseInt(json.submissionDetails.heavy.amount),
                        'lightCapacity': parseInt(json.submissionDetails.light.capacity),
                        'middleCapacity': parseInt(json.submissionDetails.middle.capacity),
                        'heavyCapacity': parseInt(json.submissionDetails.heavy.capacity),
                        'status': json.submissionDetails.status,
                        'regulatorComment': json.submissionDetails.regulatorComment
                    }
                    assets.push(currentAsset);   
                }                   
                resolve(assets);  
            }).catch((error) => {
                console.error('Error getting latest totals: ' + error);
            });
        }); 
    }, 
    updateCapacity: function(light, middle, heavy) {
        return new Promise((resolve, reject) => {  
            if ((light < 0) || (middle < 0) || (heavy < 0)) {
                reject("No/Invalid values supplied");
                return;
            }
            fetchAPI(APIPATH + '/updateOrganisationCapacity', headersWithMethod('POST',JSON.stringify({                
                    "submissions":[
                        {"commodityType": "light", "amount": light},
                        {"commodityType": "middle", "amount": middle},
                        {"commodityType": "heavy", "amount": heavy}
                    ]
                }))
            ).then((response) => {                
                resolve(response);
            }).catch((error) =>{                
                reject(error);
            })
        })

    }
};