import { getAPIPath, USER_TYPE, headersWithMethod, fetchAPI } from '../constants';
import {publicIpv4} from 'public-ip';

const APIPATH = getAPIPath(USER_TYPE.PUBLISHER);

export const publisherService = {
    /*  
        Get historic aggregated data for bar chart
    */
    getSumbissionTableData: function() {
        return new Promise((resolve, reject) => {
            
            fetchAPI(APIPATH + '/getHistoricalAggregateSubmissions', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {                                                        
                var submissionDetails = [];
                for (var i =0; i < json.submissions.length; i++) {
                    submissionDetails.push(json.submissions[i]);
                }

                resolve(submissionDetails);  
            }).catch((error) => {
                console.error('Error getting publisher submissions: ' + error);
            });
        });
    }, 
    getAggregatedAsset: function() {
        return new Promise((resolve, reject) => {            
            fetchAPI(APIPATH + '/getPublishDate', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {                                                        
                resolve(json);  
            }).catch((error) => {
                console.error('Error getting publisher aggregated asset: ' + error);
            });
        });
    },
    getAggregaedTotals: function() {
        return new Promise((resolve, reject) => {
            
            fetchAPI(APIPATH + '/getCurrentAggregateTotals', headersWithMethod('GET'))
            .then(response => response.json())
            .then(json => {                                                        
                var submissionDetails = [];
                submissionDetails.push(json.aggregateTotals);
                resolve(submissionDetails);  
            }).catch((error) => {
                console.error('Error getting publisher aggregated totals: ' + error);
            });
        });
    }, 
    approveSubmission: function() {        
        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/validateSubmissions', headersWithMethod('POST', JSON.stringify({
                "comment": "",
                "approved": true
            })))
            .then(response => response.json())
            .then(json => {
                resolve(json);
            }).catch((error) => {
                console.error('Error approving submission: ' + error);
            })
        });
    }, 
    declineSubmission: function(reason) {
        
        return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/validateSubmissions', headersWithMethod('POST', JSON.stringify({
                "comment": reason,
                "approved": false
            })))
            .then(response => response.json())
            .then(json => {
                resolve(json);
            }).catch((error) => {
                console.error('Error approving submission: ' + error);
            })
        });
    }, 
    setNewPeriod: async function(newPeriod, timezoneOffset) {
         const ipAddress =  await publicIpv4();
         return new Promise((resolve, reject) => {
            fetchAPI(APIPATH + '/setPublishDate', headersWithMethod('POST', JSON.stringify({
                "newPeriod": newPeriod, "offset":timezoneOffset, "ipAddress": ipAddress, "userName":"Platts"
            })))
            .then(response => response.json())
            .then(json => {
                resolve(json);
            }).catch((error) => {
                console.error('Error approving submission: ' + error);
            })
        });
    }
}