import React, { Component } from 'react';
import { Form, FormGroup, FormControl, Col, Button, Table, ToggleButton} from 'react-bootstrap';
import Moment from 'react-moment';
import { getAPIPath, USER_TYPE, headersWithMethod, fetchAPI } from '../../constants';

// Services 
import {operatorService} from '../../Services/operatorService.js';

// Modules 
import AlertModal from '../../Modules/AlertModal';
import { confirmAlert } from 'react-confirm-alert';

const APIPATH = getAPIPath(USER_TYPE.ADMIN);
const ADD_USER_BUTTON = "Add User";
const UPDATE_USER_BUTTON = "Update User";

class AdminView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userId: null, 
            firstName: null, 
            lastName: null, 
            emailAddress: null, 
            mobileNumber: null,
            admin: null, 
            validation: {
                firstName: null, 
                lastName: null, 
                emailAddress: null, 
                mobileNumber: null
            }, 
            participants: null, 
            buttonTitle: ADD_USER_BUTTON, 
            editMode: false,
            lightCapacity: 0,
            middleCapacity: 0, 
            heavyCapacity: 0,
            showConfirmation: false
        }

        this.setLoading = this.setLoading.bind(this);
        this.getLatestTotals = this.getLatestTotals.bind(this);
        this.showValidation = this.showValidation.bind(this)
        this.getParticipants = this.getParticipants.bind(this);
        this.editParticipant = this.editParticipant.bind(this);
        this.deleteParticipant = this.deleteParticipant.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.isNull = this.isNull.bind(this)
    }

    componentDidMount() {
        if (this.props.manageUsers) {
            this.getParticipants();
        } else {
            this.getLatestTotals();
        }
    }

    setLoading(isLoading) {        
       this.props.loadingHandler(isLoading);
    }

    deleteParticipant(i) {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Do you want to delete the user permanently?',
            buttons: [
              {
                label: 'Yes',
               onClick: () => {
                this.setLoading(true);
                let participant = this.state.participants[i];                        
                if (participant !== null && participant !== undefined) {
                    fetchAPI(APIPATH + '/deleteUser', headersWithMethod('POST', 
                        JSON.stringify({'username':participant.username}))).then((response) => {          
                        this.setLoading(false);  
                        if (response.status === 200) {
                            alert("Participant Deleted Successfully");       
                            this.resetForm();                  
                            this.getParticipants();                    
                        } else {
                            return;
                        }
                    }).catch((error) =>{
                        console.error('ERROR:', error);
                        this.setLoading(false);
                    });
                }
                }
              },
              {
                label: 'No',
                onClick: null
              }
            ],
            closeOnEscape: false,
           closeOnClickOutside: false
          });
    }

    getLatestTotals() {
        this.setLoading(true);
        operatorService.getLatestTotals().then((response) => {
            if (response && response.light) {            
                this.setState({                    
                    lightCapacity : response.light.capacity,       
                    middleCapacity: response.middle.capacity,                       
                    heavyCapacity: response.heavy.capacity
                });
            }
            this.setLoading(false);
        }).catch((error) => {
            alert('Error getting latest capcity values. Please refresh the page to try again.');
            this.setLoading(false);
        })
    }

    addParticipant(e) {

        if (this.isNull(this.state.emailAddress,this.state.firstName, this.state.lastName, this.state.mobileNumber)) {
            alert("Please complete all the fields!");
            return;
        }

        //Check for valid email address
        const emailAddress = this.state.emailAddress
        if(!emailAddress.includes('@')) {
            alert("Invalid email Address")
            return            
        }

        //Check for Country Code
        let mobileNumber = this.state.mobileNumber
        if(mobileNumber.length === 1) {
            alert("Invalid Mobile Number");
            return;
        }

        if(mobileNumber.at(0) !== '+') {
            alert("Please Enter country Code! If entered then prepend with + sign");
            return;
        }

        mobileNumber = mobileNumber.split('+')[1]        
        if(isNaN(mobileNumber)) {
            alert("Invalid Mobile Number");
            return
        }        

        this.setLoading(true);
        let organisationType = this.props.auth.user["custom:organisationType"];
        let organisationId = this.props.auth.user["custom:organisationId"];
        console.log('API Path', APIPATH);
        fetchAPI(APIPATH + '/addNewUser', headersWithMethod('POST',
            JSON.stringify({                    
                "username": this.state.emailAddress,
                "name": this.state.firstName,
                "admin": this.state.admin == null ? "0" : this.state.admin,
                "email": this.state.emailAddress,
                "lastname": this.state.lastName,
                "phone": this.state.mobileNumber, 
                "organisation": organisationId, 
                "organisationType": organisationType
        }))).then((response) => {      
            this.setLoading(false);      
            if(response.status !== 200) {
                alert("Unable to add Participant!" + response.message);
                this.resetForm();
                this.getParticipants();
            } else if (response.status === 200 || response.status === 201) {
                alert("Participant Added Successfully");
                this.resetForm();
                this.getParticipants();
            } else {
                response.json().then((json) => {
                    alert("Unable to parse the json data");
                });                
                return;
            }
        }).catch((error) =>{
            console.error('ERROR:', error);
            this.setLoading(false);
        })
    }

    updateParticipant() {
        
        if (this.state.userId == null) {
            alert("Please select a user to update");
            return;
        }

        if (this.state.emailAddress == null || this.state.firstName == null || this.state.lastName == null || this.state.mobileNumber == null) {
            alert("Please complete all the fields!");
            return;
        }

        this.setLoading(true);
        fetchAPI(APIPATH + "/updateUserDetails", headersWithMethod('POST',
            JSON.stringify({
                "username": this.state.userId,                    
                "name": this.state.firstName,
                "customadmin": this.state.admin == null ? "0" : this.state.admin,
                "email": this.state.emailAddress,
                "family_name": this.state.lastName,
                "phone": this.state.mobileNumber,
                "organisation": this.props.auth.user["custom:organisationId"],
                "organisationType": this.props.auth.user["custom:organisationType"]
        }))).then((response) => {         
            this.setLoading(false);   
            if (response.status === 200) {
                alert("Participant Updated Successfully");       
                this.resetForm();         
                this.getParticipants();
            } else {
                return;
            }
        }).catch((error) =>{
            this.setLoading(false);
            console.error('ERROR:', error);
        })
    }

    updateCapacity() {
        
        if (this.state.lightCapacity < 0) {
            alert('Please enter a valid light capacity value');
            return;
        }

        if (this.state.middleCapacity < 0) {
            alert('Please enter a valid middle capacity value');
            return;
        }

        if (this.state.heavyCapacity < 0) {
            alert('Please enter a valid heavy capacity value');
            return;
        }

        this.setLoading(true);
            
        operatorService.updateCapacity(this.state.lightCapacity, this.state.middleCapacity, this.state.heavyCapacity).then(() => {
            this.setLoading(false);
            this.setState({
                showConfirmation: true,
                confirmationTitle: "Capacity Updated Successfully",
                confirmationMessage: "Your capacity has been updated. The capacity value, is only used for calculating your current level % which is only available for you to see.", 
            })      
        }).catch((error) => {
            this.setLoading(false);
            alert("Unable to update the capacity");                    
        })
    }

    editParticipant(i) {        
        let participant = this.state.participants[i];
        if (participant !== null && participant !== undefined) {
            var user = participant.attributes;

            this.setState({
                userId: participant.username, 
                firstName: user.name, 
                lastName: user.familyName, 
                emailAddress: user.email, 
                mobileNumber: user.phoneNumber, 
                admin: user.admin, 
                buttonTitle: UPDATE_USER_BUTTON,
                editMode: true
            });
        }
    }

    getParticipants() {        
        this.setLoading(true);
        console.log('API Path', APIPATH);
        fetchAPI(APIPATH + '/getCognitoUsers', headersWithMethod('GET'), this.props).then(response => response.json())
        .then(json => {                                          
            this.setState({
                participants:json.userDetails
            });      
            this.setLoading(false);
        }).catch((error) =>{
            console.error('ERROR:', error);
            this.setLoading(false);
        })
    }

    modalAction() {
        this.setState({
            showConfirmation: false
        })
    }

    resetForm() {
         this.setState({
            userId: null, 
            firstName: null, 
            lastName: null, 
            emailAddress: null, 
            mobileNumber: null,
            admin: null, 
            validation: {
                firstName: null, 
                lastName: null, 
                emailAddress: null, 
                mobileNumber: null
            }, 
            participants: null, 
            buttonTitle: ADD_USER_BUTTON, 
            editMode: false 
        });
    }

    handleFirstNameChange(e) { 
        this.setState({firstName: e.target.value});    

        if (e.target.value.length > 0) {
            this.setState(prevState => ({
                validation: {
                    ...prevState.validation,
                    firstName: true
                }
            }))          
        } else {
            this.setState(prevState => ({
                validation: {
                    ...prevState.validation,
                    firstName: false
                }
            })) 
        }

    }
    handleLastNameChange(e) { 
        this.setState({lastName: e.target.value});    

        if (e.target.value.length > 0) {
            this.setState(prevState => ({
                validation: {
                    ...prevState.validation,
                    lastName: true
                }
            })) 
        } else {
            this.setState(prevState => ({
                validation: {
                    ...prevState.validation,
                    lastName: false
                }
            })) 
        }
    }
    handleEmailAddressChange(e) { 
        this.setState({emailAddress: e.target.value});    

        if (e.target.value.length > 0) {
            this.setState(prevState => ({
                validation: {
                    ...prevState.validation,
                    emailAddress: true
                }
            })) 
        } else {
            this.setState(prevState => ({
                validation: {
                    ...prevState.validation,
                    emailAddress: false
                }
            })) 
        }
    }
    handleMobileNumberChange(e) { 
        this.setState({mobileNumber: e.target.value});    

        if (e.target.value.length > 0) {
            this.setState(prevState => ({
                validation: {
                    ...prevState.validation,
                    mobileNumber: true
                }
            })) 
        } else {
            this.setState(prevState => ({
                validation: {
                    ...prevState.validation,
                    mobileNumber: false
                }
            })) 
        }
    }

    handleChange(column, value) {
        const newState = {};
        newState[column] = value;

        this.setState(newState);
    }

    showValidation(fieldValidated) {
        
        if (!fieldValidated) {
            return 'error';
        }

        return null;
    }

    isNull() {
        for(const i of arguments) {
            if(i === null) {
                return true
            }
        }
        return false
    }

    render() {
        let participantTable = null;

        if (this.state.participants != null && this.state.participants.length > 0) {
            participantTable = (<div className="moduleContainer">                
                    <h2>Manage Users</h2>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Created</th>                            
                            <th>Status</th>
                            <th>Actions</th>                            
                        </tr>
                        </thead>
                        <tbody> 
                             {this.state.participants.map((item, i) =>
                            <tr key={i}>                                                   
                                <td> {item.attributes['name']} &nbsp; {item.attributes['familyName']}
                                </td>
                                <td>{item.attributes['email']}</td>
                                <td>{item.attributes['phoneNumber']}</td>
                                <td><Moment format="DD MMM YYYY HH:mm">{item.userLastModifiedDate}</Moment></td>                                
                                <td>{item.userStatus}</td>
                                <td>
                                    <Button variant="light" onClick={() => this.editParticipant(i)}>Edit</Button> &nbsp;
                                    <Button onClick={() => this.deleteParticipant(i)} variant="danger">Delete</Button>
                                </td>                                
                            </tr>                               
                             )}                      
                        </tbody>
                    </Table>                    
                </div>
            )
        }

        let CapacityControl = null; 
        let ManageUsers = null;
        if (this.props.auth.user["custom:organisationType"] != null && this.props.auth.user["custom:organisationType"] === "operator" && !this.props.manageUsers) {
            CapacityControl = (
                <div className="moduleContainer dvCapcity">         

                    {this.state.showConfirmation ? <AlertModal title={this.state.confirmationTitle} message={this.state.confirmationMessage} modalAction={this.modalAction.bind(this)}/> : null}

                    <h2>Current Capacity (in thousands of barrels)</h2>

                    <p>Current capacity is the maximum amount of a type of distillate your terminal can hold. This number is used to calculate your utilization levels for pie charts on your dashboard. You can update this value at anytime.</p>
                    <p><i>If you do not store a particular type of distillate, please set the capacity to 0.</i></p><br />
                    <Form horizontal>
                        <Form.Group>
                            <Col sm={2}>                    
                                <label>Light capacity</label>  
                            </Col>
                            <Col sm={10}>
                                <Form.Control 
                                    type="text" 
                                    placeholder="00000" 
                                    onChange={e => this.handleChange('lightCapacity', e.target.value)}
                                    value={this.state.lightCapacity != null ? this.state.lightCapacity : ""} />
                                <Form.Control.Feedback />
                            </Col>
                        </Form.Group> 
                        <Form.Group>
                            <Col sm={2}>                    
                                <label>Middle capacity</label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control 
                                    type="text" 
                                    placeholder="00000" 
                                    onChange={e => this.handleChange('middleCapacity', e.target.value)} 
                                    value={this.state.middleCapacity != null ? this.state.middleCapacity : ""} />
                                <Form.Control.Feedback />
                            </Col>
                        </Form.Group> 
                        <Form.Group>
                            <Col sm={2}>                    
                                <label>Heavy capacity</label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control 
                                    type="text" 
                                    placeholder="00000" 
                                    onChange={e => this.handleChange('heavyCapacity', e.target.value)} 
                                    value={this.state.heavyCapacity != null ? this.state.heavyCapacity : ""} />
                                <Form.Control.Feedback />
                            </Col>
                        </Form.Group> 
                        <Form.Group>
                            <Col sm={10} className="col-sm-offset-2">
                                <Button type="button" variant="light" onClick={this.updateCapacity.bind(this)}>Update</Button>
                            </Col>
                        </Form.Group>
                    </Form>                    
                </div>                
            )
        }

        if (this.props.manageUsers) {
            ManageUsers = (
                <div>
                    <div className="moduleContainer addNewUser">                
                    <h2>Add new user</h2>
                    <Form>
                        <Form.Group>
                            <Col sm={2}>                    
                                <Form.Label>First name</Form.Label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter a first name" 
                                    onChange={this.handleFirstNameChange.bind(this)} 
                                    value={this.state.firstName != null ? this.state.firstName : ""} />
                                <Form.Control.Feedback />
                            </Col>
                        </Form.Group>  
                        <Form.Group>
                            <Col sm={2}>                    
                                <Form.Label>Last name</Form.Label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter a last name" 
                                    onChange={this.handleLastNameChange.bind(this)} 
                                    value={this.state.lastName != null ? this.state.lastName : ""} />
                                <Form.Control.Feedback />
                            </Col>
                        </Form.Group>              
                        <Form.Group>
                            <Col sm={2}>                    
                                <Form.Label>Email Address</Form.Label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Enter an email address" 
                                    onChange={this.handleEmailAddressChange.bind(this)} 
                                    value={this.state.emailAddress != null ? this.state.emailAddress : ""} 
                                    disabled={this.state.editMode} />
                                <Form.Control.Feedback />
                            </Col>
                        </Form.Group>         
                        <Form.Group>
                            <Col sm={2}>                    
                                <Form.Label>Mobile number</Form.Label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter a mobile number" 
                                    onChange={this.handleMobileNumberChange.bind(this)} 
                                    value={this.state.mobileNumber != null ? this.state.mobileNumber : ""} 
                                    disabled={this.state.editMode} />
                                <Form.Control.Feedback />
                            </Col>
                        </Form.Group>   
                        <Form.Group>
                            <Col sm={2}>                    
                                <Form.Label>Administrator</Form.Label>
                            </Col>
                            <Col sm={10}>                            
                                    {/* <ToggleButton
                                        type="checkbox"
                                        checked={this.state.admin != null ? this.state.admin === "1" ? true : false : false}
                                        value="1"
                                        onChange={e => this.handleChange('admin', e.target.checked ? "1" : "0")}
                                    > */}
                                    <Form.Check
                                        type="checkbox"
                                        label="Give user access to administration section"
                                        checked={this.state.admin != null ? this.state.admin === "1" ? true : false : false}
                                        value="1"
                                        onChange={e => this.handleChange('admin', e.target.checked ? "1" : "0")}
                                    /> 
                                        
                                    {/* </ToggleButton> */}
                                {/* <Checkbox onChange={e => this.handleChange('admin', e.target.checked ? "1" : "0")}  checked={this.state.admin != null ? this.state.admin === "1" ? true : false : false} inline>Give user access to administration section</Checkbox> */}
                                <Form.Control.Feedback />
                            </Col>
                        </Form.Group>
                        <Form.Group>
                            <Col sm={10} className="col-sm-offset-2">
                                <Button type="button" variant="ligth" onClick={this.state.editMode ? this.updateParticipant.bind(this) : this.addParticipant.bind(this)}>{this.state.buttonTitle}</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
                <br />
                { participantTable }
                </div>
            )
        }

        return(
            <div className="container">
                { CapacityControl }                
                { ManageUsers }                
            </div>
        );
    }

}

export default AdminView; 
