import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './FooterView.css';

class FooterView extends Component {
    
    render() {
        var d = new Date();        
        return(
            <div id="footer-view">
                <Container>
                    <Row>
                        <Col>
                            <p>Copyright © {d.getFullYear()} S&amp;P Global Platts, a division of S&amp;P Global. All rights reserved.</p>
                            <p><a href="mailto:PL_FUJAIRAHSUPPORT@spglobal.com">Contact Support</a></p>
                        </Col>
                    </Row>
                </Container>                
            </div>
        );
    }
}

export default FooterView;