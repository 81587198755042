import React, {Component} from 'react'; 
import { Table } from 'react-bootstrap';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip'

// CSS 
import './RegulatorSubmissionDataTable.css';

// Modules 
import SubmissionStatusIcon from '../../Modules/SubmissionStatusIcon';

class RegulatorSubmissionDataTable extends Component {

    constructor(props) {
        super(props); 

        this.state = {
        result: props.data
        };
    }
    
    render() {

        if (this.props.data == null) {
           return null;
        }

        var title = null; 
        if (this.props.title != null) {
            title = (<h2>{this.props.title}</h2>);
        }

        return(
            <div className="moduleContainer">
                { this.props.title == null ? null : title }          
                
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Submitted Date</th>
                            <th>Publish Date</th>
                            <th>Operator</th>
                            <th>Submitted by</th>
                            <th>Light</th>
                            <th>Middle</th>
                            <th>Heavy</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.data.map((item, i) =>
                            <tr key={i}>
                            <td><Moment format="DD MMM YYYY HH:mm">{item.submittedDate}</Moment></td>
                            <td><Moment format="DD MMM YYYY">{item.currentPeriod}</Moment></td>
                            <td>{item.orgName}</td>
                            <td>{item.userName} ({item.ipAddress})</td>
                            <td>{item.lightAmount}</td>
                            <td>{item.middleAmount}</td>
                            <td>{item.heavyAmount}</td>
                            </tr>
                        )}              
                        </tbody>
                    </Table>                    
                <ReactTooltip />
            </div> 
        );
    }

}

export default RegulatorSubmissionDataTable;